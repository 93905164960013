import React, { useState } from "react"
import { Col, Modal, Row, Button } from "react-bootstrap"
import PropTypes from "prop-types";
import { toastr } from "react-redux-toastr";
import Avatar from "react-avatar-edit";
import closeIconBlack from "../../assets/images/closeIconBlack.svg"

const AvatarModal = props => {
  const src = null;
  const [preview, setPreview] = useState(null);

  const onClose = () => {
    setPreview(null);
  }

  const onCrop = preview => {
    setPreview(preview);
  }

  const onBeforeFileLoad = elem => {
    if(elem.target.files[0].size > 2097152){
      toastr.error('Fail', 'Maximum file size is 2M')
      elem.target.value = "";
    }
  }

  const uploadAvatar = ()=> {
    props.onSubmit(preview)
  }

  return(
    // <div className="main-popup-container" show={props.show} onHide={props.onHide}>
    //   <div className="main-overlay"/>
    //   lorem ipsum
    // </div>
    <Modal
      show={props.show} onHide={props.onHide}
      className="main-popup-container add-photo-modal"
      backdrop={false}
      >
      <div className="main-overlay"/>
      <div className="main-popup">
        <Modal.Header className="header">
          <h2 className="ml-0">Add your photo</h2>
          <div className="close-popup h-auto p-0" onClick={props.onHide}>
            <img src={closeIconBlack} alt=""/>
          </div>
        </Modal.Header>
        <Modal.Body className="body">
          <Row horizontal className="w-100">
            <Col md={{span: 8, offset: 2}} className="text-center">
              <Avatar
                width='100%'
                height={300}
                label="Choose your photo"
                onCrop={onCrop}
                onClose={onClose}
                onBeforeFileLoad={onBeforeFileLoad}
                src={src}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="footer justify-content-end border-top-0">
          {/* <Button variant="secondary" onClick={props.onHide}>Cancel</Button> */}
          <button className="btn primary m-0 w-auto" onClick={uploadAvatar}>Add</button>
        </Modal.Footer>
      </div>
    </Modal>
  )
}

AvatarModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AvatarModal
