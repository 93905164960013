import {store} from '../redux/store.js';
import ApiHelper from './apiHelper.js';
import API_URL from "../consts/urls";

class AuthHelper {
  login = (email, password) => {
    let formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    return ApiHelper.post(API_URL.Login, {}, config, formData, false);
  };
  
  logout = () => {
    return ApiHelper.post(API_URL.Logout);
  };
  
  getUserRole = () => {
    return ApiHelper.get(API_URL.GetUserRole);
  };
  
  getAccessToken = () => {
    let state = store.getState();
    if (state.auth.isAuthenticated) {
      return state.auth.token;
    }
    return null;
  };
  
  getUserMembership = () => {
    return ApiHelper.get(API_URL.GetUserMembership);
  }
  
  adminLoginByToken = (user_id) => {
    return ApiHelper.get(API_URL.GetUserToken, {
      user_id: user_id
    });
  }
}

export default new AuthHelper();

