import React, {useEffect, useState} from "react";
import CustomAvatar from "../CustomAvatar";
import {Link} from "react-router-dom";
import logoutImage from "../../assets/images/logoutRed.svg";
import ApiHelper from "../../helpers/apiHelper";
import API_URL from "../../consts/urls";
import {toastr} from "react-redux-toastr";
import {useSelector, useDispatch} from "react-redux";
import {loginByToken, logout} from "../../redux/actions/auth";
import {getProfile} from "../../redux/actions/profile";
import ChangeMembership from "../Popups/Settings/changeMembership";
import BuyScans from "../Popups/Settings/buyScans";
import CancelMembership from "../Popups/cancelMembership";

const AccountInfo = ({updateSetting, setDirty}) => {
  const [autoLogin, setAutoLogin] = useState(false);
  const [batchMailNotification, setBatchMailNotification] = useState(false);
  const [showPlanPopup, setShowPlanPopup] = useState(0);
  const [cancelMembership, setShowCancelMembership] = useState(false);
  const [businessName, setBusinessName] = useState('');
  const profile = useSelector(state => state.profile.profile);
  const photo = useSelector(state => state.profile.photo);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProfile());
    ApiHelper.get(API_URL.GetBatchReport).then(res => {
      setBatchMailNotification(res.data.batch_report);
    }).catch(() => {
    })
  }, [])

  useEffect(() => {
    setBusinessName(profile.profile?.user_name);
  }, [profile.profile?.user_name]);

  const onChangeBatchMailNotification = () => {
    ApiHelper.put(API_URL.UpdateBatchReport, {}, {}, {
      batch_report: !batchMailNotification
    }).then(res => {
      setBatchMailNotification(!batchMailNotification);
      toastr.success('', res.data.message);
    }).catch(err => {
      toastr.error('Fail', 'Updating Notification State failed');
    })
  }

  const handleCloseCancelMembership = () => { setShowCancelMembership(false)};
  const onClickCancel = () => {
    setShowCancelMembership(true);
  }
  const onClickMembership = () => {
    setShowPlanPopup(1);
  }
  const onCloseMembership = () => {
    setShowPlanPopup(0);
  }
  const onClickBuyMoreScans = () => {
    setShowPlanPopup(2);
  }

  const onChangeAutoLogin = () => {
    setAutoLogin(!autoLogin);
  }

  const onChangeBusinessName = (e) => {
    setBusinessName(e.target.value);
    updateSetting({
      business_name: e.target.value
    });
    setDirty(true);
  }
  const getPercentClassName = (total, real) => {
    const percent = Math.floor(real * 100 / total);
    return `cprogress-circle p${percent}`;
  }

  return (
    <>
      {showPlanPopup === 1 &&
      <ChangeMembership handleHide={onCloseMembership}/>
      }
      {showPlanPopup === 2 &&
        <BuyScans handleHide={onCloseMembership}/>
      }
      {cancelMembership &&
        <CancelMembership
          handleHide={handleCloseCancelMembership}
        />
      }
      <h2>Account information</h2>
      <div className="account-info-row1">
        <div className="col1">
          <div className="profile-picture">
            <CustomAvatar />
          </div>
        </div>
        <div className={"col2"}>
          <div className="account-info-row3">
            <div className="username">
              <div className="fw">
                <label htmlFor="">Username</label>
                <input type="text" value={photo.full_name} disabled placeholder="JohnDoe123" />
              </div>
            </div>
            <div className="account-info-row2">
              <div className="auto-login fw-bold">
                <Link to="/reset_password">Change Password</Link>
              </div>
              <Link to="/logout" style={{display: 'flex', alignItems: 'center'}} onClick={() => dispatch(logout())}>
                Logout
                <img src={logoutImage} alt="" style={{marginLeft: '8px'}}/>
              </Link>
            </div>
          </div>
          <div className="account-info-row2 mt-4">
            <div className="username mb-0">
              <div className="fw">
                <label htmlFor="">Business/Vendor name</label>
                <input type="text" value={businessName} onChange={onChangeBusinessName} placeholder="Businees/Vender Name" />
              </div>
            </div>
          </div>
          <div className="account-info-row4 mt-4">
            <div className={'custom-control custom-checkbox'}>
              <input type="checkbox" className="custom-control-input" checked={autoLogin} onChange={onChangeAutoLogin}
                     name="auto-login" id={"auto-login"} />
              <label className="custom-control-label" htmlFor="auto-login">Auto login when opening the app</label>
            </div>
          </div>
          <div className="account-info-row4">
            <div className={'custom-control custom-checkbox'}>
              <input type="checkbox" className="custom-control-input" checked={batchMailNotification}
                     onChange={onChangeBatchMailNotification} name="email-notification" id={"email-notification"} />
              <label className="custom-control-label" htmlFor="email-notification">Enable email notifications when batch completes</label>
            </div>
          </div>
        </div>
      </div>
      <div className="memberships">
        <div className={"title-header"} >
          <h3>Membership & Buy Scans</h3>
          <div style={{display: 'flex'}}>
            <span className={'cancel-link'} onClick={onClickCancel}>Cancel</span>
            <button className={"btn primary"} style={{marginRight: '36px'}} onClick={onClickMembership}>Change Membership</button>
            <button className={"btn primary"} onClick={onClickBuyMoreScans}>Buy More Scans</button>
          </div>
        </div>
        <div className="membership-container">
          <div className="subscription-info">
            <p className="title">Subscription</p>
            <div className="info-container">
              <div className="info r1">
                <p>{profile.subscription.name}</p>
                <p>{profile.subscription.payment} $ / month</p>
              </div>
              <div className="info r2">
                <p>Next Payment On</p>
                <p>{profile.subscription.due_date}</p>
              </div>
            </div>
          </div>
          <div >
            <p className="title">Scans This Month</p>
            <div className="info-container">
              <div className="cprogress">
                <div
                  className={getPercentClassName(profile.stats.limit_scans, profile.stats.current_scans)}>
                  <span
                    className="cprogress-value">{`${Math.floor(profile.stats.current_scans * 100 / profile.stats.limit_scans)}%`}</span>
                  <div className="left-half-clipper">
                    <div className="first50-bar"/>
                    <div className="value-bar"/>
                  </div>
                </div>
              </div>

              <div className="infos">
                <div className="info">
                  <p>Used</p>
                  <p>{profile.stats.current_scans}</p>
                </div>
                <div className="info">
                  <p>Remaining</p>
                  <p>{profile.stats.limit_scans - profile.stats.current_scans}</p>
                </div>
              </div>
            </div>
          </div>
          <div >
            <p className="title">Storage Space</p>
            <div className="info-container">
              <div className="cprogress">
                <div
                  className={getPercentClassName(profile.stats.limit_storage, profile.stats.current_storage)}>
                  <span
                    className="cprogress-value">{`${Math.floor(profile.stats.current_storage * 100 / profile.stats.limit_storage)}%`}</span>
                  <div className="left-half-clipper">
                    <div className="first50-bar"/>
                    <div className="value-bar"/>
                  </div>
                </div>
              </div>

              <div className="infos">
                <div className="info">
                  <p>Used</p>
                  <p>{`${profile.stats.current_storage.toFixed(2)} GB`}</p>
                </div>
                <div className="info">
                  <p>Remaining</p>
                  <p>{`${(profile.stats.limit_storage - profile.stats.current_storage).toFixed(2)} GB`}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AccountInfo;