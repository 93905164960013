import React, {useEffect, useState} from "react";
import Switch from "react-switch";
import ebayLogo from "../../assets/images/settings/ebayLogo.svg"
import shopify from "../../assets/images/settings/shopify.svg"
import simpleAuction from "../../assets/images/settings/simpleAuction.svg"
import customCSV from "../../assets/images/settings/customCSV.svg"
import {useHistory} from "react-router";
import ApiHelper from "../../helpers/apiHelper";
import API_URL from "../../consts/urls";
import DefaultData from "../../consts/defaultData";
import {ReactComponent as OpenInNewTabRed} from "../../assets/images/openInNewTabRed.svg"
import {getBatchCardFlag} from "../../helpers/util";

const exportSettingOptions = [
  {title: 'ebay', key: 'ebay_export', icon: ebayLogo, description: 'Use the toggle to enable/disable eBay exports. You can create/edit eBay template settings with the below link.'},
  {title: 'Shopify', key: 'shopify_export', icon: shopify, description: 'Use the toggle to enable/disable Shopify exports'},
  {title: 'Simple Auction', key: 'auction_export', icon: simpleAuction, description: 'Use the toggle to enable/disable Simple Auction exports'},
  {title: 'Custom CSV', key: 'custom_export', icon: customCSV, description: 'Use the toggle to enable/disable eBay exports. You can access your custom CSV settings with the below link.'},
]
const ExportOption = ({option, checked, onChange}) => {
  const {title, key, icon, description} = option;
  const [checkedOption, setCheckedOption] = useState(checked);
  const history = useHistory();
  const onChangeOption = (checked) => {
    setCheckedOption(checked);
    onChange(key, checked);
  }
  const onClickGotoTab = () => {
    history.push({
      pathname: '/settings/export',
      search: '?' + new URLSearchParams({tab: key}).toString()
    });
  }

  return (
    <div className={"export-option"}>
      <div className={'switch'}>
        <div  className={'export-option-logo'}>
          <img src={icon} alt={""}></img>
        </div>
        <Switch onChange={onChangeOption}
                checked={checkedOption ? checkedOption : false}
                checkedIcon={false}
                uncheckedIcon={false}
                onColor={'#EA3553'}></Switch>
      </div>
      <div className={'description'}>
        <h3 className="mb-2">{title}</h3>
        <p>{description}</p>
      </div>
      {key !== 'shopify' &&
      <div className={'setting-to'} onClick={onClickGotoTab}>
        <OpenInNewTabRed className="open-in-new-icon" style={{alignSelf: 'center', height: '19px', marginRight: '6px'}}/>
        <a>Go to {title} Settings</a>
      </div>}
    </div>
  )
}
const ExportSetting = (props) => {
  const {updateSetting, setDirty} = props;
  const [settingOptions, setSettingOptions] = useState(DefaultData.defaultExportSettingOption);
  const [loading, setLoading] = useState(false);
  const onChangeOption = (key, checked) => {
    setSettingOptions({
      ...settingOptions,
      [key]: checked
    });
    setDirty(true)
  }

  useEffect(() => {
    setLoading(true);
    ApiHelper.get(API_URL.ExportSetting).then(res => {
      setSettingOptions(res.data)
    }).catch((e) => {
      setSettingOptions(DefaultData.defaultExportSettingOption);
    }).finally(() => {
      setLoading(false)
    })
  }, []);

  useEffect(() => {
    updateSetting(settingOptions);
  }, [settingOptions])
  return (
    <>
      <div className={"title"}>
        <h2>Export Settings</h2>
        {/*<button className="btn primary" onClick={onClickEditExportSettings}>Edit Export Settings</button>*/}
      </div>
      {!loading && <div className={"export-options"}>
        {exportSettingOptions.map(option =>
          (<ExportOption onChange={onChangeOption}
                        option={option}
                        checked={settingOptions[option.key]}
                        key={option.key}>
          </ExportOption>))}
      </div>}
    </>
  )
}

export default ExportSetting;