import React, { useEffect } from "react";
import {Link, useHistory} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/auth";
import { getProfilePhoto } from "../../redux/actions/profile";
import CustomLazyLoadImage from "../../components/LazyLoadImage";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import routes from "../../routes.js";
import logoImg from "../../assets/images/logo.svg";
import {ReactComponent as HelpIcon} from "../../assets/images/helpIcon.svg"

const Sidebar = (props) => {
  const history = useHistory();
  const admin_role = useSelector(state => state.auth.admin_role)
  const handleRoute = (route) => {
    if (route.path.startsWith("/")) {
      history.push(route.path);
    }
  }

  const dispatch = useDispatch()
  const profile = useSelector(state => state.profile);
  useEffect(() => {
    dispatch(getProfilePhoto());
  }, [dispatch])

  const createLinks = () => {
    let roleRoutes = []
    for (const [key, value] of Object.entries(routes)) {
      if (routes[key].role === admin_role) {
        roleRoutes = value.routes;
    }
  }

    return (
      <div className="navigation">
        {roleRoutes.filter((route)=>route.show === "true")
          .map((route, key) => (
          <div className={"nav-item" + activeRoute(route.path)} onClick={() => handleRoute(route)} key={`nav_${key}`}>
            <div className="separator"/>
            <div className="nav-icon">
              <img src={route.icon} alt=""/>
            </div>
            <h3>
              {route.name}
            </h3>
          </div>
        ))}
        <a className={"nav-item"} style={{color: 'white', textDecoration: 'none'}} href="http://support.carddealerpro.com" target="_blank">
          <div className="separator"/>
          <div className="nav-icon">
            <HelpIcon fill={'white'} style={{ width: '24px', height: '24px'}}/>
          </div>
          <h3>
            Help
          </h3>
        </a>
      </div>
    );
  };

  const activeRoute = routeName => {
    return props.location.pathname.indexOf(routeName) === 0 ? " active" : "";
  };

  return (
    <div className="sidebar">
      <div className="logo-container">
        <Link to="/">
          <img src={logoImg} alt=""/>
        </Link>
      </div>
      <div className="user-profile">
        <div className="profile-wrapper">
          <CustomLazyLoadImage src={profile.photo.profile_photo} effect={"blur"} alt="" className="user-image" />
          <div className="info">
            <h4 className="user-name">{profile.photo.full_name}</h4>
            <a href="/settings" className="profile-link">View Profile</a>
          </div>
        </div>
        <button className="log-out-button" onClick={() => dispatch(logout())}>
        <span className="iconify" data-icon="ic:twotone-log-out"></span>
        </button>
      </div>
      {createLinks()}
    </div>
  );
};

export default Sidebar;
