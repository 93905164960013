import React, {useEffect, useState} from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import UploadBannerImage from "../Popups/uploadBannerImage";
import ApiHelper from "../../helpers/apiHelper";
import API_URL from "../../consts/urls";


const Banner = ({updateSetting, setDirty}) => {
  const [showUploadBannerPopup, setShowUploadBannerPopup] = useState(false);
  const [bannerSetting, setBannerSetting] = useState(null)

  useEffect(() => {
    const getBannerOption = ApiHelper.get(API_URL.GetBannerSetting);
    const getBannerImage = ApiHelper.get(API_URL.GetBannerImage);
    Promise.all([getBannerOption,getBannerImage])
      .then(res => {
        updateBannerSetting({
          bannerOption: res[0].data.banner === "None" ? "top" : res[0].data.banner,
          bannerImage: res[1].data.banner_image,
          isBannerUpload: res[0].data.banner !== "None",
        })
      }).catch(() => {
    })
  }, [])

  const updateBannerSetting = (newSetting, isDirty = false) => {
    setBannerSetting(newSetting);
    updateSetting(newSetting);
    if (isDirty) {
      setDirty(isDirty);
    }
  }
  const handleUploadedBanner = () => {
    ApiHelper.get(API_URL.GetBannerImage)
      .then(res => {
        updateBannerSetting({
          ...bannerSetting,
          bannerImage: res.data.banner_image
        }, true);
      }).catch(err => {
    });
  }

  const handleHideUploadBannerPopup = () => {
    setShowUploadBannerPopup(false);
  }
  const handleShowUploadBannerPopup = () => {
    setShowUploadBannerPopup(true);
  }

  const onChangeSetCheckBannerUpload = () => {
    updateBannerSetting({
      ...bannerSetting,
      isBannerUpload: !bannerSetting.isBannerUpload
    }, true)
  }

  const onChangeBannerOption = (e) => {
    updateBannerSetting({
      ...bannerSetting,
      bannerOption: e.target.value
    }, true)
  }

  return (
    <>
      {showUploadBannerPopup &&
        <UploadBannerImage
          handleAction={handleUploadedBanner}
          handleHide={handleHideUploadBannerPopup}
        />
      }
      <h2>Banner</h2>
      {bannerSetting && <div style={{marginTop: '1.5rem'}}>
        <div className={'custom-control custom-checkbox'}>
          <input type="checkbox" className="custom-control-input" checked={bannerSetting.isBannerUpload}
                 onChange={onChangeSetCheckBannerUpload} name="banner-upload" id={"banner-upload"} />
          <label className="custom-control-label" htmlFor="banner-upload">Add banner to card images</label>
        </div>
        <div className={'banner-image'} onClick={handleShowUploadBannerPopup}>
          <LazyLoadImage effect={"blur"} src={bannerSetting.bannerImage} alt=""/>
          <p>Click to change/upload</p>
        </div>
        <div className={'banner-image-placement'}>
          <p className="mb-2">Placement</p>
          <select name="banner-placement" onChange={onChangeBannerOption}
                  value={bannerSetting.bannerOption}
                  disabled={!bannerSetting.isBannerUpload}>
            <option value="top">Top</option>
            <option value="bottom">Bottom</option>
          </select>
        </div>
      </div>}
    </>
    )
}

export default Banner;