import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import React, {useEffect, useState} from "react";
import {FormGroup, FormText} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components"
import {beginPaymentAction, endPaymentAction} from "../../redux/actions/payment";
import Select from "react-select"
import useToggle from "../../customHook/toggle";
import API_URL from "../../consts/urls";
import ApiHelper from "../../helpers/apiHelper";
import visaImg from "../../assets/images/visa.png"
import masterImg from "../../assets/images/master.png"
import amexImg from "../../assets/images/american-express.svg"


const StyledImg = styled.img `
  height: 40px;
`;

const CheckoutForm = props => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const paymentActionState = useSelector(state => state.payment.doingAction);
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [addNewPayment, setAddNewPayment] = useToggle(false);
  const [currentPayment, setCurrentPayment] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setEmail(props.email);
    setLoading(true)
    ApiHelper.get(API_URL.GetCustomer).then(res => {
      const paymentLists = res.data.payment.map(each => {
        return {
          ...each,
          value: each.id,
          label: `**** **** **** ${each.last4} - ${each.type}`
        }
      })
      setPaymentOptions(paymentLists);
      setCurrentPayment(paymentLists[0]);
    }).finally(() => {
      setLoading(false);
    })
  }, []);

  const changeCurrentPayment = value => {
    setCurrentPayment(value);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    let paymentMethodId;

    if (!stripe || !elements || paymentActionState) {
      return;
    }
    dispatch(beginPaymentAction());
    if (addNewPayment) {
      const payload = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });
      if (payload.error) {
        setError(payload.error.message);
        dispatch(endPaymentAction());
        return;
      }
      paymentMethodId = payload.paymentMethod.id;
    }
    else {
      paymentMethodId = currentPayment.id;
    }
    props.action(paymentMethodId, email);
  };
  
  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  const customOption = ({data}) => {
    return (
      <>
        {data.type === 'visa' &&
        <StyledImg src = {visaImg} />}
        {data.type === 'mastercard' &&
          <StyledImg src = {masterImg} />}
        {data.type === 'amex' &&
          <StyledImg src = {amexImg} />}
        <div className={"payment-card"}>
          <div>**** **** **** {data.last4}</div>
          <div>{data.type}</div>
        </div>
      </>

    )
  }
  const onChangeEmail = (e) => {
    setEmail(e.target.value)
  }

  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      display: "flex"
    })
  };
  return (
    <form className="payment-form-container-main" onSubmit={handleSubmit}>
      <div className={"membership-pay"}>
        <p>Payment Method</p>
        <p style={{color: '#EA3553', cursor: 'pointer'}} onClick={setAddNewPayment}>{addNewPayment ? 'Use Default Card' : 'Use New Card'}</p>
      </div>

      {!loading && !addNewPayment && paymentOptions.length > 0 &&
        <div>
          <Select
            defaultValue={paymentOptions [0]}
            components={{SingleValue: customOption}}
            options={paymentOptions}
            value={currentPayment}
            styles={customStyles}
            onChange={changeCurrentPayment}
            isSearchable={false}
          />
        </div>
      }
      {addNewPayment &&
        <>
          {/*<div className="inputs-row1">*/}
          {/*  <label htmlFor="email">Email Address</label>*/}
          {/*  <input id="email" name="name" type="email" placeholder="jenny.rosen@example.com"*/}
          {/*         value={email} onChange={onChangeEmail}/>*/}
          {/*</div>*/}
          <FormGroup>
            {/* <label htmlFor="card-element">Credit or debit card</label> */}
            <CardElement id="card-element" onChange={handleChange} className="stripe-container" />
            <FormText className="text-danger">{error}</FormText>
          </FormGroup>
        </>
      }
      {!loading &&
      <button type="submit" className="btn primary" disabled={paymentActionState}>
        {paymentActionState && (
          <i
            className="fa fa-refresh fa-spin"
            style={{marginRight: "5px"}}
          />
        )}
        {paymentActionState && <span>Processing...</span>}
        {!paymentActionState && <span>Pay</span>}
      </button>}
    </form>
  );
};

export default CheckoutForm;
