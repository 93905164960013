import React, {useEffect, useState, useRef} from "react";
import CurrencyInput from 'react-currency-input-field';
import DefaultData from "../../../consts/defaultData";
import UIConst from "../../../consts/UIConst";
import { getCitiesByStateIsoCode, getStates } from "../../../helpers/util";
import ChevronDownIcon from '../../../assets/images/chevron-down-blue.svg'

const EbayTemplateForm = ({ebayTemplateData = DefaultData.defaultEbayTemplate, onChange, isBatchExport = false}) => {
  const [ebayTemplateInfo, setEbayTemplateInfo] = useState({
    ...ebayTemplateData,
    state: ebayTemplateData?.state,
    city: ebayTemplateData?.city,
  });

  const [categorySettings, setCategorySettings] = useState(false)
  const [returnSettings, setReturnSettings] = useState(false)
  const [shippingSettings, setShippingSettings] = useState(false)
  const isMounted = useRef(false);
  const [citiesList, setCitiesList] = useState([]);
  const [states, setStates] = useState([]);
  useEffect(() => {
    // setStates(getStates());
    setEbayTemplateInfo(ebayTemplateData);
    isMounted.current = true;
    return () => {
      isMounted.current = false
    }
  }, [ebayTemplateData]);

  // useEffect(() => {
  //   if (!isMounted.current) {
  //     return;
  //   }
  //   const cities = getCitiesByStateIsoCode(ebayTemplateInfo?.state || 'CA');
  //   setCitiesList(cities);
  // }, [ebayTemplateInfo.state]);

  const onChangeNewEbayTemplatesNumberParams = (e) => {
    let newInfo = {
      ...ebayTemplateInfo
    };
    let numberValue = parseFloat(e.target.value);
    newInfo[e.target.name] = e.target.value;
    if (isNaN(numberValue)) {
      numberValue = 0;
    }
    setEbayTemplateInfo(newInfo);
    onChange({
      ...newInfo,
      [e.target.name]: numberValue
    });
  }
  const onChangeNewEbayTemplateParams = (e) => {
    let newInfo = {
      ...ebayTemplateInfo
    };
    // for check
    if (e.target.name === 'allow_best_offer' || e.target.name === 'free_shipping' || e.target.name === 'enable_buy_it_now_price') {
      newInfo[e.target.name] = !newInfo[e.target.name]
    }
    // boolean in select
    else if (e.target.name === 'listing_price' || e.target.name === 'returns' || e.target.name === 'calculated_method') {
      newInfo[e.target.name] = e.target.value === "true";
    }
    else {
      if (e.target.name === 'listing_type') {
        if (e.target.value === 'Auction') {
          newInfo['duration'] = UIConst.ebayTemplateDurationValues.all[2];
        } else {
          newInfo['duration'] = UIConst.ebayTemplateDurationValues.fixed[0];
        }
      }
      newInfo[e.target.name] = e.target.value;
    }
    setEbayTemplateInfo(newInfo);
    onChange(newInfo);
  }
  const onChangeCurreyInputValue = (value, name) => {
    let newInfo = {
      ...ebayTemplateInfo
    };
    newInfo[name] = isNaN(parseFloat(value)) ? 0 : parseFloat(value);

    setEbayTemplateInfo({
      ...ebayTemplateInfo,
      [name]: value
    });
    onChange(newInfo);
  }

  // const onChangeState = (e) => {
  //   let newInfo = { ...ebayTemplateInfo, state: e.target.value};
  //   setEbayTemplateInfo(newInfo);
  //   onChange(newInfo);
  // }

  return (
    <div className="ebay-template-info">
      <div className="param-section">
        <h3>Listing Information</h3>
          <div className="params-container">
            <div className={'location'}>
              {!isBatchExport &&
                <div className="fw">
                  <label htmlFor="">Template Name</label>
                  <input type="text" name="name" placeholder="Template Name" value={ebayTemplateInfo.name || ''}
                         onChange={onChangeNewEbayTemplateParams}/>
                </div>
              }
              <div className="fw">
                <label htmlFor="">City</label>
                <input type="text" name="city" placeholder="City" value={ebayTemplateInfo?.city || ''}
                       onChange={onChangeNewEbayTemplateParams}/>
                {/*<select name="city" id="city" value={ebayTemplateInfo?.city || 'Los Angeles'}*/}
                {/*       onChange={onChangeNewEbayTemplateParams}>*/}
                {/*  {citiesList.map(c => <option key={c.name} value={c.name}>{c.name}</option>)}*/}
                {/*</select>*/}
              </div>
              <div className="fw">
                <label htmlFor="">State</label>
                <input type="text" name="state" placeholder="State" value={ebayTemplateInfo?.state || ''}
                       onChange={onChangeNewEbayTemplateParams}/>
                {/*<select name="state" id="state" value={ebayTemplateInfo?.state || 'CA'}*/}
                {/*       onChange={onChangeState}>*/}
                {/*  {states.map(s => <option key={s.isoCode} value={s.isoCode}>{s.name}</option>)}*/}
                {/*</select>*/}
              </div>
              <div className="fw">
                <label htmlFor="">Zip Code</label>
                <input type="text" name="zip_code" placeholder="Zip Code" value={ebayTemplateInfo.zip_code || ''}
                       onChange={onChangeNewEbayTemplateParams}/>
              </div>
            </div>
            {/*{isBatchExport && <div className={'fw'} /> }*/}
          </div>
          <button onClick={() => setCategorySettings(!categorySettings)} className="btn-toggler">
            Advanced Settings
            <img src={ChevronDownIcon} alt="" className={`${categorySettings && 'rotated'}`} />
          </button>
          { categorySettings &&
            <div className="fw">
              <label htmlFor="">Store Category Number&nbsp;<span style={{opacity: 0.6}}>(optional)</span></label>
              <input
                type="text"
                name="store_category"
                className="w-100"
                placeholder="1"
                value={ebayTemplateInfo.store_category || ''}
                onChange={onChangeNewEbayTemplatesNumberParams}
              />
              <p className={'span-optional offers-p w-100'}>
                This field is optional, and only needed if you want to map your listing to a custom store category.
                This must match the numerical store category number, found in the ebay URL.
                If incorrect, export will have error.
              </p>
            </div>
          }
      </div>
      <div className="param-section">
        <h3>Listing Type & Price</h3>
        <div className="fw">
          <label htmlFor="listing_type">Listing Type</label>
          <select name="listing_type" id="listingType" onChange={onChangeNewEbayTemplateParams}
                  value={ebayTemplateInfo?.listing_type}>
            <option value="Auction">Auction</option>
            <option value="Fixed Price">Fixed Price</option>
          </select>
        </div>
        {ebayTemplateInfo?.listing_type !== 'Auction' &&
        <div className="params-container">
          <div className={'mb-3 custom-control custom-checkbox'}>
            <input type="checkbox" className="custom-control-input" checked={ebayTemplateInfo?.allow_best_offer}
                   name="allow_best_offer" id={"allow_best_offer"} onChange={onChangeNewEbayTemplateParams} />
            <label className="custom-control-label" htmlFor="allow_best_offer">Allow best offer</label>
          </div>
        </div>}
        {ebayTemplateInfo?.listing_type === "Fixed Price" &&
        <div className="params-container">
          <div className="fw">
            <label htmlFor="">Listing Price</label>
            <select name="listing_price" id="listingPrice" onChange={onChangeNewEbayTemplateParams}
                    value={ebayTemplateInfo?.listing_price === true ? "true" : "false"}>
              <option value="true">Standard price</option>
              <option value="false">Calculated Price</option>
            </select>
          </div>
          {ebayTemplateInfo?.listing_price === false &&
            <div className="fw">
              <label htmlFor="">Add To Price</label>
              <select name="calculated_method" id="calculated_method" onChange={onChangeNewEbayTemplateParams}
                      value={ebayTemplateInfo?.calculated_method === true ? "true" : "false"}>
                <option value="true">Fixed Amount</option>
                <option value="false">Percentage</option>
              </select>
            </div>
          }
        </div>}
        {ebayTemplateInfo?.listing_type === "Fixed Price" && ebayTemplateInfo?.listing_price === false &&
          <div className="params-container">
            {ebayTemplateInfo?.calculated_method === true ?
            <div className="fw">
              <label htmlFor="">Add Fixed Amount to Price</label>
              <input type="text" name="fixed_amount_to_price" value={ebayTemplateInfo.fixed_amount_to_price || ''}
                     onChange={onChangeNewEbayTemplatesNumberParams}/>
              <p className={'span-optional offers-p'}>Enter amount to add to the listing price so when exporting the result will be #Price# + #Fixed Amount#</p>
            </div>:
            <div className="fw">
              <label htmlFor="">Add % To Price</label>
              <input type="text" name="percent_to_price" value={ebayTemplateInfo.percent_to_price || ''}
                     onChange={onChangeNewEbayTemplatesNumberParams}/>
              <p className={'span-optional offers-p'}>Enter % to add to the listing price so when exporting the result will be #Price# + #Percentage#</p>
            </div>
            }
          </div>
        }
        {ebayTemplateInfo?.listing_type === "Auction" &&
          <div className="params-container">
            <div className="fw">
              <label htmlFor="">Start Price</label>
              <CurrencyInput className="input-format"
                             placeholder="$0.00"
                             name="start_price"
                             id={"start_price"}
                             prefix={'$'}
                             value={ebayTemplateInfo.start_price}
                             onValueChange={onChangeCurreyInputValue}/>
              <p className={'span-optional offers-p'}>Set the starting bid for all cards in the auction.</p>
            </div>
            <div className={"fw"}>
              <div className={'mt-3 custom-control custom-checkbox'}>
                <input type="checkbox" className="custom-control-input" checked={ebayTemplateInfo?.enable_buy_it_now_price}
                       name="enable_buy_it_now_price" id={"enable_buy_it_now_price"} onChange={onChangeNewEbayTemplateParams} />
                <label className="custom-control-label" htmlFor="enable_buy_it_now_price">Enable buy it now</label>
              </div>
              <p className={'span-optional offers-p'}>If enabled, the Price field from the card details will be set as the Buy It Now Value.</p>
            </div>
          </div>
        }
        {ebayTemplateInfo?.allow_best_offer &&
          <div className="params-container">
            <div className="fw">
              <label htmlFor="">Auto Decline Offers&nbsp;<span className={'span-optional'}>(optional)</span></label>
              <input type="text" name="auto_decline_offer" placeholder="50" value={ebayTemplateInfo.auto_decline_offer || ''}
                     onChange={onChangeNewEbayTemplatesNumberParams}/>
              <p className={'span-optional offers-p'}>If you use this field, you can automatically decline any offers that are below a percent (%) of your asking price.  For example, if you list a card for $100 and set the auto decline at 75%, any offer that is $75 or lower will automatically be declined.
              </p>
            </div>
            <div className="fw">
              <label htmlFor="">Auto Accept Offers&nbsp;<span className={'span-optional'}>(optional)</span></label>
              <input type="text" name="auto_accept_offer" placeholder="50" value={ebayTemplateInfo.auto_accept_offer || ''}
                     onChange={onChangeNewEbayTemplatesNumberParams}/>
              <p className={'span-optional offers-p'}>If you use this field, you can automatically accept any offers that are at or above a percent (%) of your asking price.  For example, if you list a card for $100 and set the auto accept at 85%, any offer that is $85 or higher will automatically be accepted.
              </p>
            </div>
          </div>
        }
      </div>
      <div className="param-section">
        <h3>Time & Duration</h3>
        <div className="fw">
          <label htmlFor="duration">Duration</label>
          <select name="duration" id="duration" onChange={onChangeNewEbayTemplateParams}
                  value={ebayTemplateInfo?.duration}>
            {ebayTemplateInfo?.listing_type === "Fixed Price" ?
              UIConst.ebayTemplateDurationValues.fixed.map(v => (<option value={v} key={v}>{v}</option>)) :
              UIConst.ebayTemplateDurationValues.all.map(v => (<option value={v} key={v}>{v}</option>))}
          </select>
        </div>
      </div>
      <div className="param-section">
        <h3>Shipping</h3>
        <button onClick={() => setShippingSettings(!shippingSettings)} className="btn-toggler">
          Advanced Settings
          <img src={ChevronDownIcon} alt="" className={`${shippingSettings && 'rotated'}`} />
        </button>
        { shippingSettings &&
          <div className="params-container">
            <div className="fw">
              <label htmlFor="returns">Shipping Profile&nbsp;<span style={{opacity: 0.6}}>(optional)</span></label>
              <input type="text" name="shipping_profile_name" placeholder="Shipping profile" value={ebayTemplateInfo?.shipping_profile_name || ''}
                     onChange={onChangeNewEbayTemplateParams}/>
              <p className={'span-optional offers-p'}>
                This must exactly match a business policy profile configured in eBay. If the text doesn't match, your export will fail.
              </p>
            </div>
          </div>
        }
        <div className="params-container">
          <div className="fw">
            <label htmlFor="handling_time">Handling Time</label>
            <select name="handling_time" id="handling_time" onChange={onChangeNewEbayTemplateParams}
                    value={ebayTemplateInfo?.handling_time}>
              {UIConst.ebayTemplateHandlingTimeValues.map(v => (<option value={v} key={`handling_${v}`}>{`${v} ${(v === 1) ? ` Day` : ` Days`}`}</option>))}
            </select>
          </div>
          <div className="fw">
            <label htmlFor="service">Service</label>
            <select name="shipping_service" id="shipping_service" onChange={onChangeNewEbayTemplateParams}
                    value={ebayTemplateInfo?.shipping_service}>
              {UIConst.ebayTemplateShippingServices.map(v => (<option value={v} key={`shipping_${v}`}>{v}</option>))}
            </select>
          </div>
        </div>
        <div className="params-container">
          <div className={'mb-3 custom-control custom-checkbox'}>
            <input type="checkbox" className="custom-control-input" checked={ebayTemplateInfo?.free_shipping}
                   name="free_shipping" id={"free_shipping"} onChange={onChangeNewEbayTemplateParams} />
            <label className="custom-control-label" htmlFor="free_shipping">Free Shipping</label>
          </div>
        </div>
        {!ebayTemplateInfo?.free_shipping &&
        <div className="params-container">
          <div className="fw">
            <label htmlFor="shipping_cost">Cost</label>
            <input type="text" name="shipping_cost" placeholder="Shipping Cost" value={ebayTemplateInfo?.shipping_cost || ''}
                   onChange={onChangeNewEbayTemplatesNumberParams}/>
          </div>
          <div className="fw">
            <label htmlFor="additional_items">Additional Items</label>
            <input type="text" name="additional_items" placeholder="Additional items" value={ebayTemplateInfo?.additional_items || ''}
                   onChange={onChangeNewEbayTemplateParams}/>
          </div>
        </div>}
      </div>
      <div className="param-section">
        <h3>Returns</h3>
        <button onClick={() => setReturnSettings(!returnSettings)} className="btn-toggler">
          Advanced Settings
          <img src={ChevronDownIcon} alt="" className={`${returnSettings && 'rotated'}`} />
        </button>
        { returnSettings &&
          <div className="params-container">
            <div className="fw">
              <label htmlFor="returns">Return Profile&nbsp;<span style={{opacity: 0.6}}>(optional)</span></label>
              <input type="text" name="return_profile_name" value={ebayTemplateInfo?.return_profile_name || ''}
                     onChange={onChangeNewEbayTemplateParams}/>
              <p className={'span-optional offers-p'}>
                This must exactly match a business policy profile configured in eBay. If the text doesn't match, your export will fail.
              </p>
            </div>
          </div>
        }
        <div className="params-container">
          <div className="fw">
            <label htmlFor="returns">Returns</label>
            <select name="returns" id="returns" onChange={onChangeNewEbayTemplateParams}
                    value={ebayTemplateInfo?.returns ? "true" : "false"}>
              <option value="true">Returns Accepted</option>
              <option value="false">Returns Not Accepted</option>
            </select>
          </div>
          {ebayTemplateInfo?.returns &&
          <>
            <div className="fw">
              <label htmlFor="returns_within">Returns Within</label>
              <select name="returns_within" id="returns_within" onChange={onChangeNewEbayTemplatesNumberParams}
                      value={ebayTemplateInfo?.returns_within}>
                {UIConst.ebayTemplateReturnsWithIn.map(v => (<option value={v} key={`returns_within_${v}`}>{`${v} Days`}</option>))}
              </select>
            </div>
            <div className="fw">
              <label htmlFor="refund_given_as">Refund Given As</label>
              <select name="refund_given_as" id="refund_given_as" onChange={onChangeNewEbayTemplateParams}
                      value={ebayTemplateInfo?.refund_given_as}>
                {UIConst.ebayTemplateRefundValues.map(v => (<option value={v} key={`refund_as_${v}`}>{v}</option>))}
              </select>
            </div>
            <div className="fw">
              <label htmlFor="return_shipping_paid_by">Returns Shipping Paid By</label>
              <select name="return_shipping_paid_by" id="return_shipping_paid_by" onChange={onChangeNewEbayTemplateParams}
                      value={ebayTemplateInfo?.return_shipping_paid_by}>
                {UIConst.ebayTemplateReturnShippingValues.map(v => (<option value={v} key={`return_shipping_paid_by_${v}`}>{v}</option>))}
              </select>
            </div>
          </>
          }
        </div>
        {ebayTemplateInfo?.returns &&
          <div className="add-info">
            <div className="fw">
              <label htmlFor="additional_return_info">Additional Return Info</label>
              <textarea name="additional_return_info" id="additional_return_info"
                        onChange={onChangeNewEbayTemplateParams}
                        value={ebayTemplateInfo?.additional_return_info}>
                  </textarea>
            </div>
          </div>
        }
      </div>
    </div>)
}
export default EbayTemplateForm;