import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import closeIconBlack from "../../assets/images/closeIconBlack.svg"
import ApiHelper from "../../helpers/apiHelper";
import API_URL from "../../consts/urls";
import {toastr} from "react-redux-toastr";
import AddCards from "./addCards";
import DefaultData from "../../consts/defaultData";
import UIConst from "../../consts/UIConst";


const NewBatch = (props) => {
  const {handleHide} = props;
  const [batchOtherInfo, setBatchOtherInfo] = useState({
    name: "",
    category: "",
    title: "0",
    description: "0",
    summary: "",
    cabinet: "",
    shelf: "",
    box: "",
    row: "",
    section: "",
    start_price: "",
    grade: ""
  });
  const [titleTemplates, setTitleTemplates] = useState([]);
  const [descriptionTemplates, setDescriptionTemplates] = useState([]);
  const [creating, setCreating] = useState(false);
  const [newBatchId, setNewBatchId] = useState(0)
  const history = useHistory();

  const handleCreate = () => {
    if (batchOtherInfo.name === '') {
      toastr.error('Fail', 'Batch name is empty!')
      return;
    }

    if (creating) {
      return;
    }
    setCreating(true)
    const updatedData = { ...DefaultData.defaultBatch, ...batchOtherInfo };

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    ApiHelper.post(API_URL.CreateBatch, {}, config, updatedData)
      .then(res => {
        toastr.success('Success', "New Batch is created");
        setNewBatchId(res.data.batch_id);
        setCreating(false);
      })
      .catch(err=>{
        toastr.error('Fail', err.response.data.message);
        setCreating(false);
      })
      .finally(() => {
      })
  }

  const onCloseAddCards = () => {
    handleHide();
    history.push(`/batches/`);
  }

  const onChangeBatchOtherInfo = (e) => {
    let newBatchInfo = {...batchOtherInfo};
    if (e.target.name === 'category' && e.target.value === 'default') {
      newBatchInfo[e.target.name] = '';
    } else {
      newBatchInfo[e.target.name] = e.target.value;
    }
    setBatchOtherInfo(newBatchInfo);
  }

  useEffect(() => {
    ApiHelper.get(API_URL.TitleDescriptionKey).then(res => {
      const titles = res.data.titles.map(title => ({
        'id': title[0],
        'value': title[1]
      }));
      const descriptions = res.data.descriptions.map(description => ({
        'id': description[0],
        'value': description[1]
      }));
      if (titles.length === 0) {
        titles.push({
          'id': 0,
          'value': 'Standard Title'
        })
      }
      if (descriptions.length === 0) {
        descriptions.push({
          'id': 0,
          'value': 'Standard Description'
        })
      }
      setBatchOtherInfo({
        ...batchOtherInfo,
        title: titles[0].id,
        description: descriptions[0].id,
        category: UIConst.batchCategories[0].key,
      });
      setTitleTemplates(titles);
      setDescriptionTemplates(descriptions);
    }).catch(e => {
      setTitleTemplates([])
      setDescriptionTemplates([]);
    });
  }, []);

  if (newBatchId !== 0) {
    return (
      <AddCards batchId={newBatchId} handleHide={onCloseAddCards} handleAction={onCloseAddCards}/>
    )
  }
  return (
    <div className="new-batch">
      <div className="main-popup-container create-batch">
          <div className="main-overlay"/>
          <div className="main-popup">
            <div className="header">
              <h2>Create Batch</h2>
              <div className="close-popup" onClick={handleHide}>
                <img src={closeIconBlack} alt=""/>
              </div>
            </div>
            <div className="body">
              <div className="inputs-container">
                <div className="text-input collection-name">
                  <label htmlFor="collection-name">Batch Name <span style={{color: 'red'}}>*</span></label>
                  <input type="text" name="name" id="collection-name" placeholder="Enter batch's name here"
                         onChange={onChangeBatchOtherInfo}/>
                </div>
                <div className={'new-batch-params'}>
                  <div className="fw">
                    <label htmlFor="">Category</label>
                    <select name="category" id=""
                            value={batchOtherInfo.category === '' ? 'Multiple Sports' : batchOtherInfo.category}
                            onChange={onChangeBatchOtherInfo}>
                      {UIConst.batchCategories.map(category =>{
                        return (<option value={category.key} key={category.key}>{category.value}</option>)
                      })}
                    </select>
                  </div>
                  <div className="fw">
                    <label htmlFor="start_price">Price&nbsp;<span style={{opacity: 0.6}}>(optional)</span></label>
                    <input name={'start_price'} id={'start_price'} value={batchOtherInfo.start_price}
                           onChange={onChangeBatchOtherInfo}/>
                  </div>
                  <div className="fw">
                    <label htmlFor="grade">Condition&nbsp;<span style={{opacity: 0.6}}>(optional)</span></label>
                    <input name={'grade'} id={'grade'} value={batchOtherInfo.grade} onChange={onChangeBatchOtherInfo}/>
                  </div>
                </div>
              </div>
              <div className="other-popup-settings-container">
                <div className="other-settings">
                  <h3 className="underline-style">Title & Description Template</h3>
                  <div className="other-settings-inputs">
                    <div className="fw">
                      <label htmlFor="">Title template</label>
                      <select name="title" id=""
                              value={batchOtherInfo.title}
                              onChange={onChangeBatchOtherInfo}>
                        {titleTemplates.map((title, i) => {
                          return (
                            <option value={title.id} key={title.id}>{title.value}</option>
                          )
                        })}
                      </select>
                    </div>
                    <div className="fw">
                      <label htmlFor="">Description template</label>
                      <select name="description" id=""
                              value={batchOtherInfo.description}
                              onChange={onChangeBatchOtherInfo}>
                        {descriptionTemplates.map((desc, i) => {
                          return (
                            <option value={desc.id} key={desc.id}>{desc.value}</option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="other-popup-settings-container">
                  <div className="other-settings inventory-locations">
                    <h3 className="underline-style">Inventory Location</h3>
                    <div className="other-settings-inputs">
                      <div className="hw">
                        <label htmlFor="">Cabinet</label>
                        <input type="text" name="cabinet" value={batchOtherInfo.cabinet} onChange={onChangeBatchOtherInfo}/>
                      </div>
                      <div className="hw">
                        <label htmlFor="">Shelf</label>
                        <input type="text" name="shelf" value={batchOtherInfo.shelf} onChange={onChangeBatchOtherInfo}/>
                      </div>
                      <div className="hw">
                        <label htmlFor="">Box</label>
                        <input type="text" name="box" value={batchOtherInfo.box} onChange={onChangeBatchOtherInfo}/>
                      </div>
                      <div className="hw">
                        <label htmlFor="">Row</label>
                        <input type="text" name="row" value={batchOtherInfo.row} onChange={onChangeBatchOtherInfo}/>
                      </div>
                      <div className="hw">
                        <label htmlFor="">Section</label>
                        <input type="text" name="section" value={batchOtherInfo.section} onChange={onChangeBatchOtherInfo}/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inputs-container">
                  <div className="fw">
                    <label htmlFor="">Description</label>
                    <textarea name="summary" id="" placeholder="Please some comments about this batch"
                              value={batchOtherInfo.summary}
                              style={{width: '100%'}}
                              onChange={onChangeBatchOtherInfo}></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer">
              <div className="btn primary" onClick={handleCreate}>Create</div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default NewBatch;