import React, {useEffect, useState} from "react";
import editIconDark from "../../assets/images/editIconDark.svg";
import {ReactComponent as DeleteIcon} from "../../assets/images/settings/deleteBlack.svg"
import ApiHelper from "../../helpers/apiHelper";
import API_URL from "../../consts/urls";
import {deepCloneObject} from "../../helpers/commonHelper";

const CustomFields = ({updateSetting, setDirty}) => {
  const [customFields, setCustomFields] = useState([]);
  useEffect(() => {
    ApiHelper.get(API_URL.GetCustomFields).then(res => {
      const fields = res.data.custom_fields.map(field => {
        return {
          name: field[0],
          value: field[1],
          edit: false
        }
      });
      setCustomFields(fields);
      updateSetting(fields);
    }).catch(e => {
      setCustomFields([]);
      updateSetting([]);
    });
    return () => {
      setCustomFields([]);
    }
  }, []);

  const onClickAddCustomField = () => {
    const newField = {
      name: `new_custom field ${customFields.length + 1}`,
      value: '',
      edit: false
    }
    onChangeCustomField(customFields.length, newField);
  }

  const onChangeCustomField = (index, v) => {
    const newFields = [...customFields.slice(0, index), v, ...customFields.slice(index + 1)];
    setCustomFields(newFields)
    setDirty(true);
    updateSetting(newFields);
  }

  const onChangeFieldAttrib = (index, k, v) => {
    const modified = deepCloneObject(customFields[index]);
    modified[k] = v;
    onChangeCustomField(index, modified);
  }

  const onDeleteField = (index, field) => {
    const newFields = [...customFields.slice(0, index), ...customFields.slice(index + 1)];
    setCustomFields(newFields)
    setDirty(true)
    updateSetting(newFields);
  }
  return (
    <>
      <div className={'field-header'}>
        <h2>Custom Fields</h2>
        <button className="btn primary" onClick={onClickAddCustomField} >+ Add Custom Field</button>
      </div>
      <div className={'fields-container'}>
        {customFields?.map((field, i) => (
          <div className={'field-body'} key={`field_${i}`}>
            <div className={`${field.edit ? 'is-edit' : 'is-disabled'} field-heading`}>
              <input
                type={'text'}
                className="heading-input"
                style={field.edit ? {border: "1px solid rgba(46, 60, 84, 0.2)"} : {border: "unset"}}
                value={field.name}
                disabled={!field.edit}
                onChange={(e) => onChangeFieldAttrib(i, 'name', e.target.value)}/>
              <div className={'custom-field-edit'}>
                <img src={editIconDark} alt="" onClick={() => onChangeFieldAttrib(i, 'edit', !field.edit)}/>
                <DeleteIcon fill={'grey'} style={{ width: '20px', height: '20px'}} onClick={() => onDeleteField(i, field)}/>
              </div>
            </div>
            <input className="value-input" type={"text"} value={field.value} onChange={(e) => onChangeFieldAttrib(i, 'value', e.target.value)}/>
          </div>
        ))}
      </div>
    </>
  )
}

export default CustomFields;