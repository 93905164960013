import ACTION_TYPE from "../../consts/actions";

const initialState = {
  batchesData: [],
  isLoadingBatchesData: false,
  reloadBatch: -1,
  processingBatchestate: false,
};

const batchesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.BATCHES_LIST_REQUEST:
      return {
        ...state,
        isLoadingBatchesData: true
      }
    case ACTION_TYPE.GET_BATCH_INFO:
      const batchListData = action.data;
      return {
        ...state,
        isLoadingBatchesData: false,
        batchesData: batchListData,
        processingBatchestate: batchListData.findIndex(batchData => batchData.status.toUpperCase() === "PROCESSING") !== -1
      }
    case ACTION_TYPE.BATCHES_LIST_FAILED:
      return {
        ...state,
        isLoadingBatchesData: false,
      }
    case ACTION_TYPE.BATCH_UPDATED:
      return {
        ...state,
        reloadBatch: action.data,
      }
    case ACTION_TYPE.PROCESSING_BATCH_INFO:
      return {
        ...state,
        processingBatchestate: action.data.processing_batch,
      }
    case ACTION_TYPE.RESET_BATCH_UPDATED:
      return {
        ...state,
        reloadBatch: -1
      }
    default:
      return state
  }
}
export default batchesReducer;