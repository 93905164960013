import React  from "react";
import {Link} from "react-router-dom";

export default function Footer() {
  return (
    <footer id="root-footer">
      <p>© 2023 Card Dealer Pro - All rights reserved</p>
        <div className="footer-links">
          <Link to="/about">About CDP</Link>
          <Link to="/privacy_policy">Privacy Policy </Link>
          <Link to="/terms_conditions">Terms and Conditions</Link>
          <a href="http://support.carddealerpro.com" target="_blank">Support</a>
          <a href="http://support.carddealerpro.com" target="_blank">Contact Us</a>
        </div>
    </footer>
  );
}
