import React, {useCallback, useEffect, useMemo, useState} from "react";
// import ReactTooltip from "react-tooltip";
import { AgGridReact } from "ag-grid-react";
import loadingOverlay from "../Widget/loadingOverlay";
import DefaultData from "../../consts/defaultData";
import {getBatchCardColumnFlag, getBatchCardFlag, getCardSpreadColumnName, numericCompare} from "../../helpers/util";
import UIConst from "../../consts/UIConst";
import {ReactComponent as FlagIcon} from "../../assets/images/batches/flag.svg";
import SpreadSheetCardPreview from "./spreadSheetCardPreview";
import ApiHelper from "../../helpers/apiHelper";
import API_URL from "../../consts/urls";
import FillColumn from "../Popups/fillColumn";

let flagFilter = 'all';
const BatchSpreadSheetView = (props) => {
  const { gridData, setGridData, setIsDirty, gridAPI, onGridReady, batchInfo, handleSaveData } = props;
  const { batch_id, batchCustomFields, settingOptions } = batchInfo;
  const [currentSelectedLoading, setCurrentSelectedLoading] = useState(false);
  const [currentSelectedCard, setCurrentSelectedCard] = useState(null);
  const [previewCard, setPreviewCard] = useState(false);
  const [batchTemplateFilter, setBatchTemplateFilter] = useState('ebay')
  const [fillColumnPopupShow, setFillColumnPopupShow] = useState(false);
  const [flagFilterValue, setFlagfilterVal] = useState('all');

  const [flagCounts, setFlagCounts] = useState({
    [UIConst.flagColor.GREEN]: 0,
    [UIConst.flagColor.YELLOW]: 0,
    [UIConst.flagColor.RED]: 0,
  });
  const [colDefs, setColDefs] = useState([]);

  useEffect(() => {
    let newGridData = gridData.map((_card, i) => {
      return updateCardFlag({..._card});
    });
    setGridData(newGridData);
  }, [batchTemplateFilter]);

  useEffect(() => {
    const newFlagCounts = {
      [UIConst.flagColor.GREEN]: 0,
      [UIConst.flagColor.YELLOW]: 0,
      [UIConst.flagColor.RED]: 0,
    }
    gridData.forEach(data => {
      if (data.flagValue === 2) {
        newFlagCounts[UIConst.flagColor.YELLOW] ++;
      } else if (data.flagValue === 3) {
        newFlagCounts[UIConst.flagColor.RED] ++;
      } else {
        newFlagCounts[UIConst.flagColor.GREEN] ++;
      }
    });
    setFlagCounts(newFlagCounts);
    // updateColumns();
  }, [gridData]);

  useEffect(() => {
    updateColumns();
  }, [batchCustomFields, batchTemplateFilter])
  const updateColumns = () => {
    const flagColumn = {
      field: 'flag',
      minWidth: 70,
      cellRenderer: 'cellRenderer',
    };
    const newColDefs = [...DefaultData.batchSpreadColumns, ...batchCustomFields].map((_column, index) => {
      const columnWidth = getDefaultColumnWidth(_column);
      const hide = getBatchCardColumnFlag(batchTemplateFilter, _column) === 0;
      return {
        headerName: getCardSpreadColumnName(_column),
        field: _column,
        minWidth: columnWidth,
        hide: hide,
        headerCheckboxSelection: (index === 0),
        checkboxSelection: (index === 0),
        comparator: numericCompare,
        cellStyle: (params) => {return cellStyle(params, batchTemplateFilter)},
        valueGetter: valueGetter
      }
    });
    setColDefs([flagColumn, ...newColDefs]);
  }
  const updateCardFlag = (card) => {
    const { flagValue, field } = getBatchCardFlag(batchTemplateFilter, card);
    card.flagValue = flagValue;
    card.flagField = field;
    return card;
  }

  const onRowClick = (e) => {
    if (currentSelectedCard == null) {
      setCurrentSelectedCard(e.data);
      setPreviewCard(true);
      setCurrentSelectedLoading(false);
    } else if (e.data.card_id !== currentSelectedCard.card_id) {
      setCurrentSelectedLoading(true);
      ApiHelper.get(API_URL.GetMarvinValue, {
        card: e.data.card_id
      }, {}, {}).then(res => {
        setCurrentSelectedCard({
          ...e.data,
          avg_value: res.data.avg_value,
          high_value: res.data.high_value,
          low_value: res.data.low_value
        });
        setPreviewCard(true);
      }).catch(err => {
        setCurrentSelectedCard(e.data);
        setPreviewCard(true);
      }).finally(() => {
        setCurrentSelectedLoading(false);
      })
    } else {
      // setCurrentSelectedCard(null);
    }
  }
  const onCellValueChanged = (e) => {
    let newGridData = gridData.map((_card) => {
      if (_card.card_id !== e.data.card_id) {
        return _card;
      }
      return updateCardFlag({...e.data, status: 1})
    })
    setIsDirty(true);
    setGridData(newGridData);
  }

  const onChangeTemplateFilter = (e) => {
    setBatchTemplateFilter(e.target.value);
  }

  const handleFillColumnPopup = () => {
    setFillColumnPopupShow(true);
  }

  const handleFillValue = (columnValue) => {
    const {column, value} = columnValue;
    // batchSpreadData
    let newGridData = gridData.map((_card) => {
      return updateCardFlag({..._card, [column]: value, status: 1})
    })
    setIsDirty(true);
    setGridData(newGridData);
  }

  const onClickFlagFilter = (e, flag) => {
    flagFilter = flag;
    setFlagfilterVal(flag);
    if (gridAPI) {
      gridAPI.onFilterChanged();
    }
  }

  const getDefaultColumnWidth = useCallback((column) => {
    if (column in UIConst.defaultSpreadColumnWidth) {
      return UIConst.defaultSpreadColumnWidth[column]
    }
    return 110;
  }, []);

  const cellRenderer = (params) => {
    if (params.data) {
      let fillColor = UIConst.flagColor.GREEN;
      let toolTipString = UIConst.toolTips.green;
      if (params.data.flagValue === 2) {
        fillColor = UIConst.flagColor.YELLOW;
        toolTipString = UIConst.toolTips.yellow + ` (${getCardSpreadColumnName(params.data.flagField)})`;
      } else if (params.data.flagValue === 3) {
        fillColor = UIConst.flagColor.RED;
        toolTipString = UIConst.toolTips.red + ` (${getCardSpreadColumnName(params.data.flagField)})`;
      }
      return <span className={'batch-flag-tooltip'}
        // data-for={'flag'}
        // data-tip={toolTipString}
        // data-iscapture="true"
        //            aria-describedby={'flag'}
        style={{margin: 0, alignSelf: 'center'}}>
          <FlagIcon fill={fillColor} style={{ width: '20px', height: '20px'}}/>
          <span className="tooltiptext">
              {toolTipString}
          </span>
        </span>
    }
    return null;
  }

  const valueGetter = (params) => {
    const fieldName = params.colDef.field;
    if (fieldName === 'grader') {
      return params.data[fieldName].toUpperCase();
    }
    return params.data[fieldName];
  }

  const rowClassRules = {
    'row-yellow-back': function(params) { return params.data.flagValue === 2 },
    'row-red-back': function(params) { return params.data.flagValue === 3 }
  };

  const cellStyle = (params, templateFilter) => {
    const fieldName = params.colDef.field;
    if (params.data && params.data.flagValue !== 0) {
      if (fieldName === 'title' && params.data[fieldName].length > 80) {
        return {
          borderColor: UIConst.flagColor.RED
        }
      }
      if (fieldName === 'grader' && params.data?.graded === 'Ungraded') {
        return null;
      }
      let flagValue = getBatchCardColumnFlag(templateFilter, fieldName);
      if (params.data[fieldName] === null || params.data[fieldName] === "") {
        if (flagValue === 2) {
          return {
            borderColor: UIConst.flagColor.YELLOW
          }
        } else if (flagValue === 3) {
          return {
            borderColor: UIConst.flagColor.RED
          }
        }
      }
    }
    return null;
  };

  const isExternalFilterPresent = () => {
    return flagFilter !== 'all';
  }

  const doesExternalFilterPass = (params) => {
    switch(flagFilter) {
      case UIConst.flagColor.GREEN:
        return params.data && params.data.flagValue === 0;
      case UIConst.flagColor.YELLOW:
        return params.data && params.data.flagValue === 2;
      case UIConst.flagColor.RED:
        return params.data && params.data.flagValue === 3;
    }
    return true;
  }
  return (
    <>
      {fillColumnPopupShow &&
        <FillColumn
          columns={[...DefaultData.batchSpreadColumns, ...batchCustomFields]}
          saveValue={handleFillValue}
          handleHide={() => setFillColumnPopupShow(false)}/>
      }
      <div className="sec-header">
        <div className={'flag-filters'}>
          <div id='all' className={'flag' + (flagFilterValue === 'all' ? ' selected': '')} onClick={(e) => onClickFlagFilter(e, 'all')}>
            All ({flagCounts[UIConst.flagColor.GREEN] + flagCounts[UIConst.flagColor.YELLOW] + flagCounts[UIConst.flagColor.RED]})
          </div>
          {Object.keys(flagCounts).map(k => {
            return (<div className={'flag' + (flagFilterValue === k ? ' selected': '')} key={k} onClick={(e) => onClickFlagFilter(e, k)}>
              <span style={{margin: 0, alignSelf: 'center'}}>
                <FlagIcon fill={k} style={{ width: '20px', height: '20px'}}/>
                &nbsp;({flagCounts[k]})
               </span>
            </div>);
          })}
        </div>
        <div className="spreadsheet-view-actions">
          <div className={'filter-options-container'}>
            <p>View:</p>
            <select name="template-filter" id="template-filter" onChange={onChangeTemplateFilter}
                           value={batchTemplateFilter}>
              {UIConst.batchTemplateFilter.filter(v => ((v.exportSettingOption === 'standard' || settingOptions[v.exportSettingOption]) && v.key !== 'custom_csv'))
                .map(v => (<option value={v.key} key={v.key}>{v.batchFieldsOption}</option>)
              )}
            </select>
          </div>
          <div className="btn secondary small fill-column-btn" style={{marginLeft: '12px'}}
               onClick={handleFillColumnPopup}>
            Fill column
          </div>
          <div className="btn primary small save-table-btn" style={{marginLeft: '12px'}} onClick={handleSaveData}>
            Save
          </div>
        </div>
      </div>
      {/*<ReactTooltip*/}
      {/*  className={'batch-flag-tooltip'}*/}
      {/*  id="flag"*/}
      {/*  place={'right'}*/}
      {/*  type={'dark'}*/}
      {/*  effect={'float'}*/}
      {/*  multiline={true}*/}
      {/*/>*/}
      <div className="spreadsheet-container">
        <div
          id="myGrid"
          className="ag-theme-alpine grid-class"
        >
          <AgGridReact
            rowData={gridData}
            onGridReady={onGridReady}
            defaultColDef={{
              flex: 1,
              editable: true,
              resizable: true,
              sortable: true,
            }}
            columnDefs={colDefs}
            onCellValueChanged={onCellValueChanged}
            onRowClicked={onRowClick}
            frameworkComponents={{
              customLoadingOverlay: loadingOverlay,
              cellRenderer: cellRenderer,
              valueGetter: valueGetter
            }}
            loadingOverlayComponent={'customLoadingOverlay'}
            overlayNoRowsTemplate={'No data to show'}
            rowSelection={'multiple'}
            rowClassRules={rowClassRules}
            isExternalFilterPresent={isExternalFilterPresent}
            doesExternalFilterPass={doesExternalFilterPass}
            stopEditingWhenCellsLoseFocus
            tooltipShowDelay={0}
            tooltipHideDelay={2000}
          >
          </AgGridReact>
        </div>
        { previewCard &&
          <SpreadSheetCardPreview
              isLoading={currentSelectedLoading}
              currentSelectedCard={currentSelectedCard}
              batch_id={batch_id}
              onClosePreview={setPreviewCard}
          />}
      </div>
    </>
    )
}

export default BatchSpreadSheetView;