const API_URL = {
  // user & setting
  Login: '/api/auth/login/',
  Logout: '/api/auth/logout/',
  GetUserRole: '/api/user/role/',
  Register: '/api/auth/register/',
  EmailResend: '/api/user/auth/resend_email/', //request for verification
  PasswordReset: '/api/auth/forgot-password/',    //request for reset
  PasswordResetConfirm: '/api/auth/reset-password/', //confirm for new password
  CreateCategory: '/api/user/create_category/',

  AccountActivate: '/api/user/activate/',
  GetUserMembership: '/api/user/membership/',
  GetProfile: '/api/user/profile/',
  GetProfilePhoto: '/api/user/get_profile_photo/',
  GetCategory: '/api/user/get_category/',
  RemoveCategory: '/api/user/remove_category/',
  GetBatchDefault: '/api/user/get_batch_default/',
  UpdateBatchDefault: '/api/user/update_batch_default/',
  CreateBatch: '/api/batch/create/',
  UpdateProfilePhoto: '/api/user/update_profile_photo/',
  GetMarvinValue: '/api/batch/marvin_values/',
  GetGLECredential: '/api/user/get_gle_info/',
  SaveGLECredential: '/api/user/save_gle_info/',

  UpdateBusinessName: '/api/user/update_business_name/',
  UpdateBatchReport: '/api/user/update_batch_report/',
  GetBatchReport: '/api/user/batch_report_status/',

  GetBannerImage: '/api/user/banner_image/',
  UpdateBannerImage: '/api/user/banner_image/',
  GetBannerSetting: '/api/user/banner_setting/',
  UpdateBannerSetting: '/api/user/banner_setting/',

  TitleDescriptionKey: '/api/user/title_description_key/',
  TemplateSetting: '/api/user/title_description_setting/',

  SKUSetting: '/api/user/sku_setting/',
  // dashboard
  GetDashboardMembership: '/api/dashboard/membership/',
  GetDashboardBatchList: '/api/dashboard/batch_list/',
  GetDashboardBatchCards: '/api/dashboard/batch_cards/',
  GetDashboardStats: '/api/dashboard/stats/',
  GetCardStaticDetail: '/api/batch/card_static_detail/',
  GetCardMatchDetail: '/api/batch/card_match_detail/',

  // batch
  GetBatchList: '/api/batch/list/',
  GetBatchListDetail: '/api/batch/list_detail/',
  GetSpreadsheetListDetail: '/api/batch/list_detail_spreadsheet/',
  ExportBatch: '/api/batch/export/',
  ExportEbay: '/api/batch/export_ebay/',
  RemoveCard: '/api/batch/remove_card/',
  UpdateCard: '/api/batch/update_card/',
  UpdateAllCardsSpreadSheet: '/api/batch/update_card_spreadsheet/',
  RemoveBatch: '/api/batch/remove_batch/',
  ProcessingStatus: '/api/batch/processing_status/',
  GetBatchOneDetail: '/api/batch/one_detail/',
  UpdateBatchStatus: '/api/batch/update_status/',
  UpdateBatchName: '/api/batch/edit_name/',
  AddBannerToCards: '/api/batch/add_banner/',

  // contact
  Contact: '/api/contact/',

  // search
  SearchAPI: '/api/search/',

  // scan
  SearchAdd: '/api/scan/search_add/',
  SearchCardDetail: '/api/search/card_detail/',
  BulkAdd: '/api/scan/bulk_add/',
  BuyScans: '/api/payment/buy_scans/',
  ResubmitBatch: '/api/scan/resubmit_batch/',

  //subscription
  BuySubscription: '/api/payment/buy_subscription/',
  GetAPIKey: '/api/payment/api_key/',

  //admin
  GetAdminDashboard: '/api/admin/dashboard/',
  GetUserStats: '/api/admin/user_management/',
  GetUsersinXdays: '/api/admin/user_date/',
  SearchUser: '/api/admin/user_search/',
  GetUserDetail: '/api/admin/user_detail/',
  UpdateUserMembership: '/api/admin/membership_update/',
  SetAdminUser: '/api/admin/set_admin/',
  SetUserSuspend: '/api/admin/set_suspend/',
  SetDefaultPassword: '/api/admin/set_password/',
  ActivateUser: '/api/admin/activate_user/',
  UpdateUserProfile: '/api/admin/user_update/',
  GetUserToken: '/api/admin/user_token/',
  UpdateScans: '/api/admin/scans_update/',

  //download
  Integration: '/api/integration/',

  //payment
  CancelMembership: '/api/payment/cancel_membership/',
  GetCustomer: '/api/payment/customer/',

  //custom fields in settings
  GetCustomFields: '/api/user/custom_fields_settings/',
  UpdateCustomFields: '/api/user/custom_fields_settings/',

  //ebay template
  EbayTemplate: '/api/user/ebay_template_settings/',
  EbayTemplateDetail: '/api/user/ebay_template_detail/',
  EbayTemplateDefault: '/api/user/ebay_template_default/',
  //export setting
  ExportSetting: '/api/user/export_settings/'
}

export default API_URL;

