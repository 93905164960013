import axios from 'axios';
import AuthHelper from './authHelper.js';
// import {store} from '../redux/store.jsx';
// import {refreshToken} from '../redux/actions/auth.js';
import NProgress from 'nprogress';

axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  NProgress.start();
  return config;
}, function (error) {
  // Do something with request error
  NProgress.done();
  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Do something with response data
  NProgress.done();
  return response;
}, function (error) {
  NProgress.done();
  // Do something with response error
  return Promise.reject(error);
});

const withUrl = (url) => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return url
  } else {
    return process.env.REACT_APP_HOST + url
  }
}

const withAuth = (headers = {}) => {
  return {
    ...headers,
    'Authorization': `Bearer ${AuthHelper.getAccessToken()}`
  }
};

const base = (method, url, params = {}, headers = {}, data = {}, secure = true) => {
  if(secure) {
    return axios({
      method,
      url: withUrl(url),
      data,
      headers: withAuth(headers),
      params,
    })
  } else {
    return axios({
      method,
      url: withUrl(url),
      data,
      headers,
      params,
    })
  }
};

const ApiHelper = {};

['get', 'post', 'put', 'patch', 'delete', 'options'].forEach(method => {
  ApiHelper[method] = base.bind(null, method);
});

export default ApiHelper;
