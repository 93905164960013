import React, {useEffect, useState} from "react";
import Select from "react-select";


const SelectBatch = props => {
  const [selected, setSelected] = useState();
  const {onChange, value} = props;

  const handleChange = value => {
    setSelected(value);
    onChange(value);
  };

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const style = {
    control: base => ({
      ...base,
      // This line disable the blue border
      border: 'none',
      boxShadow: 'none',
      height: '100%',
      cursor: 'pointer'
    })
  };
  return (
    <Select
      className={props.className}
      options={props.options}
      styles={style}
      components={{
        IndicatorSeparator: () => null
      }}
      value={selected}
      onChange={handleChange}
    />
  )
}

export default SelectBatch;
