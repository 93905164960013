import React, {useEffect, useState} from "react";
import filterIcon from "../../assets/images/filterIcon.svg"
import sortIcon from "../../assets/images/sortIcon.svg"
import addIconWhite from "../../assets/images/addIconWhite.svg"
import noCardsInBatch from "../../assets/images/noCardsInBatch.svg"
import lockOpenIcon from "../../assets/images/lockOpenIcon.svg"
import lockClosedIcon from "../../assets/images/lockClosedIcon.svg"
import {ReactComponent as RefreshIcon} from "../../assets/images/batches/refresh-icon.svg";
import 'react-lazy-load-image-component/src/effects/blur.css';
import {getBatchList, getProcessingBatch} from "../../redux/actions/batches";
import {useDispatch, useSelector} from "react-redux";
import {Spinner} from "react-bootstrap";
import NewBatch from "../../components/Popups/newBatch";
import {getFormatVal} from "../../helpers/commonHelper";
import {Link} from "react-router-dom";
import CustomLazyLoadImage from "../../components/LazyLoadImage";
import ApiHelper from "../../helpers/apiHelper";
import API_URL from "../../consts/urls";
import {toastr} from "react-redux-toastr";


const Batches = () => {
  const [showCreateNewBatch, setShowCreateNewBatch] = useState(false);
  const batchesData = useSelector(state => state.batches.batchesData);
  const isLoadingBatch = useSelector(state => state.batches.isLoadingBatchesData);
  const processingBatcheState = useSelector(state => state.batches.processingBatchestate);
  const dispatch = useDispatch();

  const [batchGetParam, setBatchGetParam] = useState({
    sort: "-updated_at",
    'filter[status]': "Open"
  });
  const [refreshInterval, setRefreshInterval] = useState(-1);
  
  const createAutoRefresh = () => {
    if (refreshInterval === -1) {
      const autoInterval = setInterval(()=>{
        if (processingBatcheState) {
          dispatch(getBatchList(batchGetParam));
        }
        dispatch(getProcessingBatch());
      }, 10000)
      setRefreshInterval(autoInterval);
    }
  }

  useEffect(() => {
    createAutoRefresh();
    return () => {
      if (refreshInterval !== -1) {
        clearInterval(refreshInterval);
        setRefreshInterval(-1);
      }
    };
  });
  
  useEffect(() => {
    dispatch(getBatchList(batchGetParam));
  }, [dispatch, batchGetParam, processingBatcheState]);
  
  const getBatchStatusClass = (status) => {
    if (status.toUpperCase() === 'OPEN') {
      return "batch-status open";
    }
  
    if (status.toUpperCase() === 'PROCESSING') {
      return "batch-status processing";
    }
  
    if (status.toUpperCase() === 'CLOSED' || status.toUpperCase() === 'PENDING') {
      return "batch-status closed";
    }
  }
  
  const onChangeBatchSort = (e) => {
    setBatchGetParam({
      ...batchGetParam,
      sort: e.target.value
    });
  }
  
  const onChangeBatchFilter = (e) => {
    setBatchGetParam({
      ...batchGetParam,
      'filter[status]': e.target.value
    })
  }
  
  const handleNewBatch = () => {
    setShowCreateNewBatch(true);
  }
  const handleHideNewBatch = () => {
    setShowCreateNewBatch(false);
    dispatch(getBatchList(batchGetParam));
  }

  const resubmitBatch = (batchId) => {
    ApiHelper.post(API_URL.ResubmitBatch, {}, {}, {
      batch_id: batchId,
    }).then(res => {
      toastr.success('Success', res.data.message);
    }).finally(() => {
      dispatch(getBatchList(batchGetParam));
    })
  }
  return (
    <div className="batches flex-grow-1 d-flex flex-column">
      { showCreateNewBatch &&
        <NewBatch handleHide={handleHideNewBatch}/>
      }
      
      <div className="content-header-main">
        <div className="column left">
          <h1>Batches</h1>
        </div>
        <div className="column right">
          <div className="input dropdown">
            <img src={filterIcon} alt=""/>
            <select id="batchFilterSelect" value={batchGetParam['filter[status]']}
                    onChange={onChangeBatchFilter} className={"batch-sort-select"}>
              <option value={'All'}>All</option>
              <option value={'Open'}>Open</option>
              <option value={'Closed'}>Closed</option>
            </select>
          </div>
          
          <div className="input dropdown">
            <img src={sortIcon} alt=""/>
            <select id="batchSortSelect" value={batchGetParam.sort}
                          onChange={onChangeBatchSort} className={"batch-sort-select"}>
              <option value={'-updated_at'}>Modified: Newest to Oldest</option>
              <option value={'updated_at'}>Modified: Oldest to Newest</option>
              <option value={'name'}>A to Z</option>
              <option value={'-name'}>Z to A</option>
              <option value={'-created_at'}>Created: Newest to Oldest</option>
              <option value={'created_at'}>Created: Oldest to Newest</option>
            </select>
          </div>
          <div className="btn primary create-new-batch-btn" onClick={handleNewBatch}>
            <img src={addIconWhite} alt=""/>
            <p>New</p>
          </div>
        </div>
        <div className="btn primary round floating create-new-batch-btn">
          <img src={addIconWhite} alt=""/>
        </div>
      </div>
      <div className="content-container position-relative flex-grow-1">
        <div className="batches-container">
          { isLoadingBatch &&
          <div className='component-loading-overlay'>
            <Spinner animation="border" role="status" className="position-absolute top-50 start-50">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
          }
          { !isLoadingBatch && batchesData.map((batchData, idx) => {
            return (
              <Link to={batchData.status.toUpperCase() !== 'PROCESSING' ? `/batches/${batchData.batch_id}`: '/batches'} className="batch" key={batchData.batch_id}>
                <div className="batch-images">
                  <div className="middle">
                    <img src={noCardsInBatch} effect={"blur"} alt=""/>
                  </div>
                  <div className="top">
                    <CustomLazyLoadImage src={batchData.image} effect={"blur"} alt=""/>
                    </div>
                  </div>
                <div className="batch-info flex-fill">
                  <div className="info-header">
                  <h3 className="batch-name">{batchData.name}</h3>
                    <div className={getBatchStatusClass(batchData.status)}>
                      {(batchData.status.toUpperCase() === 'OPEN') && <img src={lockOpenIcon} alt=""/>}
                      {(batchData.status.toUpperCase() === 'CLOSED' || batchData.status.toUpperCase() === 'PENDING') && <img src={lockClosedIcon} alt=""/>}
                      <p>{batchData.status}</p>
                      {batchData.status.toUpperCase() === 'PROCESSING' &&
                      <Spinner animation="border" role="status" style={{width: '1.2rem', height: '1.2rem'}}>
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                      }
                    </div>
                    {batchData.resubmit_ret === true &&
                      <div className="resubmit-container" onClick={() => resubmitBatch(batchData.batch_id)}>
                        <RefreshIcon fill={'#396DC5'}></RefreshIcon>
                      </div>
                    }
                  </div>
                  <div className="batch-description">
                    <p className="batch-category">
                      {batchData.category}
                    </p>
                    <p className="batch-cards-num">
                      {batchData.status.toUpperCase() === 'PROCESSING' ?
                        batchData.submitted_cards : batchData['total_cards']} Cards
                    </p>
                  </div>
                  <div className="cards-num-info">
                    <div className="batch-cards-matched">
                      <span>{batchData.status.toUpperCase() === 'PROCESSING' ? "Completed" : "Matched"}</span>
                      <p>{batchData.status.toUpperCase() === 'PROCESSING' ? getFormatVal(batchData.completed_cards)
                        : batchData.matched_cards}</p>
                    </div>
                    <div className="batch-cards-manual">
                      <span>{batchData.status.toUpperCase() === 'PROCESSING' ? "In Processing" : "Manual Review"}</span>
                      <p>{batchData.status.toUpperCase() === 'PROCESSING' ? batchData.submitted_cards-batchData.completed_cards :batchData.total_cards-batchData.matched_cards}</p>
                    </div>
                  </div>
                </div>
                <div className="batch-dates">
                  <p>Created: {batchData['created_date']}</p>
                  <p>Modified: {batchData['modified_date']}</p>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Batches;
