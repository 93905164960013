import React from 'react';
import ReactDOM from 'react-dom';

import {ConnectedRouter} from 'connected-react-router'
import {PersistGate} from 'redux-persist/integration/react';
import {Provider} from 'react-redux';

import {history, persistor, store} from "./redux/store";
import {Route, Switch} from "react-router-dom";
import Layout from "./containers/Layout";
import ReduxToastr from 'react-redux-toastr'

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/sass/style.scss";
import loadable from '@loadable/component'

import reportWebVitals from './reportWebVitals';
import PrivateRoute from "./components/PrivateRoute";
import Register from "./containers/Login/register";
import Welcome from "./containers/Login/welcome";
import EmailVerification from "./containers/Login/email_verification";
import ResetPassword from "./containers/Login/reset_password";
import ResetPasswordEmail from "./containers/Login/reset_passwordrequest";
import Membership from "./components/Membership";

const Login = loadable(() => import('./containers/Login'))

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <React.Fragment>
            <Switch>
              <Route exact path="/login" component={Login}/>
              <Route exact path="/login/email_verification" component={EmailVerification}/>
              <Route exact path="/register" component={Register}/>
              <Route exact path="/register/welcome" component={Welcome}/>
              <Route exact path="/reset_password" component={ResetPasswordEmail}/>
              <Route exact path="/auth/reset_password" component={ResetPassword}/>
              <Route exact path="/membership" component={Membership}/>
              //<Route exact path="/support" render={() => (window.location = "https://sportscardscanner.zendesk.com/hc/en-us")} />
              <PrivateRoute path="/" component={Layout}/>
            </Switch>
            <ReduxToastr timeOut={3000} transitionIn="fadeIn" transitionOut="fadeOut"/>
          </React.Fragment>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
