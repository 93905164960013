import React, {useEffect, useState} from "react";
import windowsImg from "../../assets/images/windows.svg"
import appleImg from "../../assets/images/apple.svg"
import desktopIntegrationImg from "../../assets/images/desktopAppIntegration.png"
import gleControlImg from "../../assets/images/gleControlPanel.png"
import FileDownload from "js-file-download";
import { toastr } from "react-redux-toastr";
import API_URL from "../../consts/urls";
import AuthHelper from "../../helpers/authHelper";
import axios from "axios";

const Downloads = props => {
  const downloadApplication = (osType) => {
    let binaryFileName = "";
    let url = API_URL.Integration;
    if (osType === 'Windows') {
      binaryFileName = "CardDealerProSetup.exe";
      url = url + "windows/"
    } else if (osType === 'Mac') {
      binaryFileName = "CardDealerPro_Installer.pkg";
      url = url + "mac/"
    } else {
      return;
    }
    toastr.success('', `Download will start soon [${binaryFileName}]`);
    var windowReference = window.open();
    axios.get(url, {
      headers: {'Authorization': `Token ${AuthHelper.getAccessToken()}`},
    }).then(res => {
      windowReference.location = res.data.url;
    }).catch(err => {
      toastr.error('error', `Failed to download [${binaryFileName}]`);
    });
  };
  
  return (
    <div className="app-integrations flex-grow-1 d-flex flex-column">
      <div className="content-container flex-grow-1">
        <section className="integration desktop-app">
          <div className="integration-info">
            <h2>Desktop App</h2>
            <p className="integration-description">
              The Card Dealer Pro Desktop App connects to your online CardDealerPro and SportsCardScanner accounts and
              allows you to utilize your flatbed or autofeed scanner to scan (front and back), automatically crop, and
              watermark up to 3500 cards per hour.
            </p>
            <p className="integration-description">
              Our AI matching engine will then automatically document your cards including year, set, card #, player,
              team, subset/parallel/insert, serial number, variation, attributes (Rookie, First Bowman, Refractor...etc)
              and team. If the card is graded it will capture the grading company, numeric grade, abbreviated grade (GD,
              VG, EX..etc) and Cert #.
            </p>
        
            <div className="integration-actions">
              <p>Get it now for</p>
              <div className="integration-actions-container">
                <div className="integration-action" onClick={() => downloadApplication('Windows')}>
                  <img src={windowsImg} alt="" />
                    Windows
                  <span>(CDP Version 1.3)</span>
                </div>
                <div className="integration-action" onClick={() => downloadApplication('Mac')}>
                  <img src={appleImg} alt="" />
                    Mac
                  <span>(CDP Version 1.3)</span>
                </div>
              </div>

            </div>
          </div>
          <div className="integration-image">
            <img src={desktopIntegrationImg} alt="" />
          </div>
        </section>
    
    
        {/*<section className="integration gle-control-panel">*/}
        {/*  <div className="integration-info">*/}
        {/*    <h2>GLE Tech Control Panel</h2>*/}
        {/*    <p className="integration-description">*/}
        {/*      A Multiple Channel Selling Tool for Collectibles*/}
        {/*    </p>*/}
        {/*    <div className="integration-info-features">*/}
        {/*                <span className="label">*/}
        {/*                    Features*/}
        {/*                </span>*/}
        {/*      <h3>List it once, sell it everywhere</h3>*/}
        {/*      <p>An Easy to Use Multiple Channel Online Inventory Management System Optimized for Collectibles.</p>*/}
        {/*      <p>A Multiple Channel Platform that allows you to sell on eBay, Amazon, Etsy, and other Online*/}
        {/*        Marketplaces at the same time.</p>*/}
        {/*      <p>Connects directly with all selling channels to send listings and retrieve sales, updating channels as*/}
        {/*        needed. The Control Panel stores settings and access information for all channels you utilize,*/}
        {/*        automating the process.</p>*/}
        {/*      <p>Our clients use the GLE Tech.com Control Panel in many different ways. Some sell only eBay, or only on*/}
        {/*        Amazon, or only on their own website. Some sell on a combination of those channels, and some sell on all*/}
        {/*        of them at the same time. When you create inventory using the Control Panel, your listings are*/}
        {/*        automatically configured with default settings for wherever you sell.</p>*/}
        {/*    </div>*/}
        
        {/*    <div className="integration-actions">*/}
        {/*      <p>Launching March 1st, 2022</p>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className="integration-image">*/}
        {/*    <img src={gleControlImg} alt="" />*/}
        {/*  </div>*/}
        {/*</section>*/}
      </div>
    </div>
  )
}

export default Downloads;