import React, {useEffect, useState} from "react";
import {Spinner} from "react-bootstrap";
import chevron from "../../assets/images/chevron-right icon.svg"
import SelectBatch from "../../components/Select/selectBatch";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useHistory, Link } from "react-router-dom";

import {connect} from "react-redux";
import {getBatchList} from "../../redux/actions/batches";
import API_URL from "../../consts/urls";
import ApiHelper from "../../helpers/apiHelper";
import {getProfile} from "../../redux/actions/profile";
import News from "../../components/Widget/news";
import CustomLazyLoadImage from "../../components/LazyLoadImage";

const Dashboard = (props) => {
  const [batchOptions, setBatchOptions] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [batchCards, setBatchCardsData] = useState([]);
  const history = useHistory();
  
  const [stats, setStats] = useState({
    "lifetime_scans": 0,
    "total_batch": 0,
    "open_batch": 0,
    "saved_time": "0h, 0m",
    "membership": "",
    "membership_since": "",
    "limit_scan": 0,
    "scans_this_month": 0
  });
  
  const loadDashboardData = (isMounted) => {
    setLoadingPreview(true);
    ApiHelper.get(API_URL.GetDashboardBatchList).then(res => {
      if (isMounted) {
        let batchOptions = [];
        res.data['batch_list'].forEach((batchData, i) => {
          batchOptions.push({
            value: batchData['batch_id'],
            label: batchData['name']
          });
        })
        setBatchOptions(batchOptions);
        if (batchOptions.length > 0) {
          onBatchChange(batchOptions[0]);
        }
      }
    }).catch(err => {
      if (isMounted) {
        setBatchOptions([]);
      }
    })
    ApiHelper.get(API_URL.GetDashboardStats).then(res => {
      if (isMounted) {
        setStats(res.data.stats)
      }
    }).catch(err => {
      if (isMounted) {
        setStats({
          "lifetime_scans": 0,
          "total_batch": 0,
          "open_batch": 0,
          "saved_time": "0h, 0m",
          "membership": "",
          "membership_since": "",
          "limit_scan": 0,
          "scans_this_month": 0});
      }
      
    }).finally(() => {
      if (isMounted) {
        setLoadingPreview(false);
      }
    })
  }
  useEffect(() => {
    let isMounted = true;
    props.getProfile();
    loadDashboardData(isMounted);
    return () => { isMounted = false };
  }, []);
  
  // useEffect(() => {
  //   let isMounted = true;
  //   loadDashboardData(isMounted);
  //   return () => { isMounted = false };
  // }, [props.batches.batchesData]);
  
  const onBatchChange = (option) => {
    setSelectedBatch(option);
    getBatchDetail(option.value);
  }
  
  const handleOnUpgrade = () => {
    history.push('/settings');
  }
  const handleBatchView = (batch_id) => {
    history.push(`/batches/${batch_id}`);
  }
  
  const handleClickCard = (card_id) => {
    if (selectedBatch) {
      const currentBatch = selectedBatch.value;
      history.push(`/batches/${currentBatch}/${card_id}`);
    }
  }
  
  const getBatchDetail = (batch_id) => {
    setLoadingPreview(true);
    ApiHelper.get(API_URL.GetDashboardBatchCards, {
      batch: batch_id
    }).then(res => {
      setBatchCardsData(res.data.card);
    }).catch(err => {
      setBatchCardsData([]);
    }).finally(() => {
      setLoadingPreview(false);
    })
  }
  
  const getScanLimitWidth = () => {
    const percent = (stats.scans_this_month*100 / stats.limit_scan).toFixed(0);
    return `${percent}%`;
  }
  
  return (
    <div className="dashboard">
      <div className="content-container h-100">
        <div className="content-container-row2">
          <div className="content-card batches-preview">
            <div className="header">
              <h2 className="m-0">Preview</h2>
              <div className="header-actions">
                <div className="action select select-batch" style={{width: '250px'}}
                >
                  <SelectBatch
                    options={batchOptions}
                    className={'select-batch'}
                    value={selectedBatch}
                    onChange={onBatchChange}
                  />
                </div>
                <div className="separator"/>
                <Link to="/batches" className="action">
                  View all
                </Link>
              </div>
            </div>
            <div className="separator"/>
            <div className="body">
              <div className="cards-container position-relative">
                {loadingPreview &&
                <div className='component-loading-overlay'>
                  <Spinner animation="border" role="status" className="position-absolute top-50 start-50">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
                }
                {!loadingPreview &&
                batchCards.map((_batch, key) => {
                  return (
                    <div className="scard" key={key} onClick={() => handleClickCard(_batch.card_id)}>
                      <div className="card-content">
                        <CustomLazyLoadImage
                          src={_batch['front_image']}
                          effect={"blur"}
                          alt=""
                        />
                        {/*<img src={_batch['front_image']} alt=""/>*/}
                        <div className="card-info">
                          <div className="card-name">
                            {_batch.title}
                          </div>
                          <div className="card-date-added">
                            {_batch['added_date']}
                          </div>
                        </div>
                      </div>
                    </div>)
                })}
              </div>
            </div>
          </div>
          <div className="content-card batches-inf">
            <div className="content-card stats">
              <div className="header">
                <h2>Stats</h2>
              </div>
              <div className="separator"/>
              <div className="body">
                <div className="stat-item">
                  <p>Lifetime Scans</p>
                  <span>{stats.lifetime_scans}</span>
                </div>
                <div className="separator"/>
                <div className="stat-item">
                  <p>Batches Created</p>
                  <span>{stats.total_batch}</span>
                </div>
                <div className="separator"/>
                <div className="stat-item">
                  <p>Open Batches</p>
                  <span>{stats.open_batch}</span>
                </div>
                <div className="separator"/>
                <div className="stat-item">
                  <p>Time Saved Using CDP</p>
                  <span>{stats.saved_time}</span>
                </div>
                <div className="separator"/>
                <div className="stat-item">
                  <p>Member since</p>
                  <span>{stats.membership_since}</span>
                </div>
                <div className="separator"/>
                <div className="stat-item">
                  <p>Membership</p>
                  <span>{stats.membership}</span>
                </div>
                <div className="separator"/>
                <div className="stat-item scans-left">
                  <div className="scans-this-month">
                    <p>Scans this month</p>
                    <span>{stats.scans_this_month} / {stats.limit_scan}</span>
                  </div>
                  <div className="bar">
                    <div className="loaded" style={{width: getScanLimitWidth()}}/>
                  </div>
                  <button className="btn secondary upload_btn" onClick={handleOnUpgrade}>
                    Upgrade to get more
                  </button>
                </div>
              </div>
            </div>
            <div className="paragraph" />
            <div className="content-card batches">
              <div className="header">
                <h2>Batches</h2>
                <div className="header-actions">
                  <div className="separator"/>
                  <Link to="/batches" className="action">
                    View all
                  </Link>
                </div>
              </div>
              <div className="separator"/>
              <div className="body batch-list">
                {batchOptions.map((_batch) => {
                  return (
                      <Link to={`/batches/${_batch.value}/`} key={_batch.value}>
                        <div className="collection" onClick={() => handleBatchView(_batch.value)}>
                          <p>{_batch.label}</p>
                          <img src={chevron} alt=""/>
                        </div>
                        <div className="separator"/>
                      </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        { /*
        <div className="content-container-row2">
          <div className="content-card recent-news">
            <div className="header">
              <h2 className="m-0">Recent News</h2>
              <div className="header-actions"/>
            </div>
            <div className="separator"/>
            <div className="news-container">
              <div className="body">
                <News />
              </div>
            </div>
          </div>
          
          <div className="content-card batches">
            <div className="header">
              <h2>Batches</h2>
              <div className="header-actions">
                <div className="separator"/>
                <Link to="/batches" className="action">
                  View all
                </Link>
              </div>
            </div>
            <div className="separator"/>
            <div className="body batch-list">
              {batchOptions.map((_batch) => {
                return (
                  <Link to={`/batches/${_batch.value}/`} key={_batch.value}>
                    <div className="collection" onClick={() => handleBatchView(_batch.value)}>
                      <p>{_batch.label}</p>
                      <img src={chevron} alt=""/>
                    </div>
                    <div className="separator"/>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
        */ }
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  batches: state.batches,
});

const mapDispatchToProps = {
  getDashboardBatchList: getBatchList,
  getProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);