import React, {useCallback, useEffect, useRef, useState} from "react";
import {toastr} from "react-redux-toastr";
import {useHistory, useParams} from "react-router";
import 'react-medium-image-zoom/dist/styles.css'
import 'react-lazy-load-image-component/src/effects/blur.css';
import {debounce} from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";

import {ReactComponent as ArrowIcon} from "../../assets/images/breadCrumbIcon.svg";

import galleryView from "../../assets/images/galleryView.svg"
import excelIcon from "../../assets/images/excelIcon.svg"
import addIconWhite from "../../assets/images/addIconWhite.svg"
import lockOpen16 from "../../assets/images/lockOpen16.svg"
import lockClosedIcon from "../../assets/images/lockClosedIcon.svg"
import deleteRed from "../../assets/images/deleteRed.svg"
import bannerImage from "../../assets/images/banner.svg"

import ApiHelper from "../../helpers/apiHelper";
import API_URL from "../../consts/urls";

import AddCards from "../../components/Popups/addCards";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import menuImg from "../../assets/images/moreOptionsVerticleIconWhite.svg"

import {setResetBatchReload} from "../../redux/actions/batches";
import {AlertYesNoModal} from "../../components/Popups/alert";
import { getBatchCardFlag } from "../../helpers/util";
import DefaultData from "../../consts/defaultData";
import ExportBatch from "../../components/Popups/exportBatch";
import RouteLeavingGuard from "../../components/Popups/prompt";
import BatchGalleryView from "../../components/Batch/galleryView";
import BatchSpreadSheetView from "../../components/Batch/spreadSheetView";

const BatchDetail = (props) => {
  const history = useHistory();
  const {batch_id} = useParams();
  const batchDetailInitData = {
    list: {
      "name": "",
      "category": "",
      "total_cards": "",
      "status": "",
      "created_date": "",
      "modified_date": ""
    },
    cards: []
  };
  const [showExportBatch, setShowExportBatch] = useState(false);
  const [addCards, setAddCards] = useState(false);
  const [batchDetailData, setBatchDetailData] = useState(batchDetailInitData);
  const [batchSpreadData, setBatchSpreadData] = useState([]);
  const [batchCustomFields, setBatchCustomFields] = useState([]);
  const [gridData, setGridData] = useState([]);
  
  const [loadingBatchList, setLoadingBatchList] = useState(false);
  const [loadingSpreadData, setLoadingSpreadData] = useState(false);
  
  const [currentView, setCurrentView] = useState('gallery');
  const [gridAPI, setGridAPI] = useState(null);
  const [removeBatchMenuVisible, setRemoveBatchMenuVisible] = useState(false);

  const reloadBatch = useSelector(state => state.batches.reloadBatch)
  const [removeBatch, setRemoveBatch] = useState(false);
  const [removeSelectedCardModal, setRemoveSelectedCardModal] = useState(false);
  const [bannerOption, setBannerOption] = useState('None');
  const [settingOptions, setSettingOptions] = useState(DefaultData.defaultExportSettingOption);
  const [saving, setSaving] = useState(false);

  const dispatch = useDispatch();
  const _isMounted = useRef(true);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    getBatchListDetail(batch_id);
    getSpreadListDetail(batch_id);
    getBannerOption();
    getExportSettingOptions();
    return () => {
      _isMounted.current = false
    }
  }, []);
  
  useEffect(() => {
    if (reloadBatch !== -1) {
      getBatchListDetail(batch_id);
      getSpreadListDetail(batch_id);
      dispatch(setResetBatchReload());
    }
  }, [reloadBatch]);
  
  useEffect(() => {
    if (batchDetailData.cards.length > 0) {
      let newGridData = batchSpreadData.map((_card, i) => {
        const dbCard = {};
        const cardDetail = getCardDetail(_card.card_id);
        if (cardDetail !== undefined) {
          for (let _key in cardDetail) {
            dbCard[_key] = cardDetail[_key];
          }
        }
        let newCard = {...dbCard, ..._card};
        const { flagValue, field } = getBatchCardFlag('ebay', newCard);
        newCard.flagValue = flagValue;
        newCard.flagField = field;
        return newCard;
      });
      setGridData(newGridData);
    }
  }, [batchDetailData.cards, batchSpreadData]);

  useEffect(() => {
    if (gridAPI) {
      if (loadingSpreadData === true) {
        setTimeout(() => {
          gridAPI.showLoadingOverlay();
        });
      }
    }
  }, [loadingSpreadData]);

  const getBannerOption = () => {
    ApiHelper.get(API_URL.GetBannerSetting).then(res=> {
      setBannerOption(res.data.banner === "None" ? "top" : res.data.banner)
    })
  }

  const getExportSettingOptions = () => {
    ApiHelper.get(API_URL.ExportSetting).then(res => {
      setSettingOptions(res.data)
    }).catch((e) => {
      setSettingOptions(DefaultData.defaultExportSettingOption);
    });
  }
  const getBatchListDetail = (batch_id) => {
    setLoadingBatchList(true);
    return ApiHelper.get(API_URL.GetBatchListDetail, {
      batch: batch_id
    }).then(res => {
      doActionOnMounted(() => setBatchDetailData(res.data));
    }).catch(err => {
      doActionOnMounted(() => setBatchDetailData(batchDetailInitData));
    }).finally(() => {
      doActionOnMounted(() => setLoadingBatchList(false));
    })
  }

  const doActionOnMounted = (fn) => {
    if (_isMounted.current === true) {
      fn();
    }
  }
  const getSpreadListDetail = (batch_id) => {
    setLoadingSpreadData(true);
    return ApiHelper.get(API_URL.GetSpreadsheetListDetail, {
      batch: batch_id
    }).then(res => {
      doActionOnMounted(() => {
        setBatchSpreadData(res.data.cards.map(card => {
          res.data.custom_fields.forEach(c_f => {
            card[c_f[0]] = c_f[1];
          });
          return card;
        }));
        setBatchCustomFields(res.data.custom_fields.map(custom_field => custom_field[0]));
      })
    }).catch(err => {
      doActionOnMounted(() => setBatchSpreadData([]))
    }).finally(() => {
      setLoadingSpreadData(false);
    })
  }

  const handleSaveData = () => {
    // batchSpreadData
    if (saving) {
      return;
    }
    setSaving(true);
    const updateData = gridData.map(b => {
      for (let i = 0; i < 10; i ++) {
        b[`custom_field${i + 1}`] = '';
      }
      return b;
    })
    ApiHelper.put(API_URL.UpdateAllCardsSpreadSheet, {}, {}, {
      cards_info: JSON.stringify(updateData)
    }).then(res => {
      getSpreadListDetail(batch_id);
      toastr.success('Success', res.data.message);
    }).catch(err => {
      toastr.error('Fail', err.response.data.message);
    }).finally(() => {
      setIsDirty(false);
      setSaving(false);
    })
  }
  const handleSelectAll = () => {
    let newCardsData = batchDetailData.cards.map(card => {
      return {...card, selected: true}
    })
    setBatchDetailData({
      ...batchDetailData,
      cards: newCardsData
    });
  }
  const handleDeselectAll = () => {
    let newCardsData = batchDetailData.cards.map(card => {
      return {...card, selected: false}
    })
    setBatchDetailData({
      ...batchDetailData,
      cards: newCardsData
    });
  }
  
  const handleRemoveSelectedModal = () => {
    setRemoveSelectedCardModal(true);
  }
  
  const handleHideRemoveSelectedModal = () => {
    setRemoveSelectedCardModal(false);
  }
  
  const handleRemoveSelected = () => {
    let cardsToRemove;
    if (currentView === 'gallery') {
      cardsToRemove = batchDetailData.cards.filter((_card) => _card.selected).map(_card => _card.card_id);
    } else {
      const selectedNodes = gridAPI.getSelectedNodes();
      cardsToRemove = selectedNodes.map(_node => {
        return _node.data.card_id;
      });
    }
    ApiHelper.delete(API_URL.RemoveCard, {}, {}, {
      card_id: JSON.stringify(cardsToRemove)
    }).then(res => {
      toastr.success('Success', 'Card is removed successfully');
      getBatchListDetail(batch_id);
      getSpreadListDetail(batch_id);
      handleHideRemoveSelectedModal();
    }).catch(err => {
      toastr.error('Fail', err.response.data.message);
    })
  }
  
  const handleShowExportBatch = () => {
    setShowExportBatch(true)
  }
  const handleHideExportBatch = () => {
    setShowExportBatch(false)
  }
  
  const handleShowAddCards = () => {
    setAddCards(true)
  }
  const handleHideAddCards = () => {
    setAddCards(false)
  }
  
  const handleViewClick = (viewName) => {
    setCurrentView(viewName)
  }
  
  const getCardDetail = (card_id) => {
    return batchDetailData.cards.find((_card) => (_card.card_id === card_id));
  }
  const onGridReady = (params) => {
    setGridAPI(params.api);
    if (loadingBatchList || loadingSpreadData) {
      params.api.showLoadingOverlay();
    }
  };

  const handleRemoveBatchMenu = useCallback(() => {
    setRemoveBatchMenuVisible(!removeBatchMenuVisible);
  }, [removeBatchMenuVisible]);
  
  const handleRemoveBatchModal = useCallback(() => {
    setRemoveBatch(true)
  }, []);

  const handleChangeBatchStatus = () => {
    let newStatus = "Closed";
    if (batchDetailData.list.status === "Closed") {
      newStatus = "Open"
    }
    ApiHelper.put(API_URL.UpdateBatchStatus, {}, {}, {
      batch_id: batch_id,
      batch_status: newStatus
    }).then(res => {
      toastr.success('Success', `Batch status is updated`);
      setBatchDetailData({
        ...batchDetailData,
        list: {
          ...batchDetailData.list,
          status: newStatus
        }
      })
    }).finally(() => {

    })
  }
  const handleRemoveBatchHide = useCallback(() => {    setRemoveBatch(false);
  }, []);
  
  const handleRemoveBatch = () => {
    ApiHelper.delete(API_URL.RemoveBatch, {}, {}, {
      batch_id: batch_id
    }).then(res => {
      toastr.success('Success', `The batch - [${batchDetailData.list.name}] is removed`);
      history.push('/batches');
    }).catch(err => {
      toastr.error('Fail', err.response.data.message);
    })
  }
  
  const updateBatchName = (newBatchName) => {
    ApiHelper.put(API_URL.UpdateBatchName, {}, {}, {
      batch_id: batch_id,
      batch_name: newBatchName
    }).then(res=> {
      toastr.success('Success', `Batch name is updated`);
      setBatchDetailData({
        ...batchDetailData,
        list: {
          ...batchDetailData.list,
          name: newBatchName
        }
      })
    })
  }
  const debouncedUpdateBatchName = debounce(updateBatchName, 5000)
  const onChangeBatchName = (e) => {
    debouncedUpdateBatchName(e.target.innerText);
  }

  const debounceBannerToCards = debounce(() => {
    ApiHelper.post(API_URL.AddBannerToCards, {}, {}, {
      batch_id: batch_id
    }).then(res => {
      toastr.success('Success', res.data.message);
      history.push('/batches');
    }).catch(err => {
      toastr.error('Error', err.response.data.message);
    });
  }, 2000);
  const handleAddBannerToCards = () => {
    debounceBannerToCards();
  }

  const navigate = (path) => {
    history.push(path);
  }

  return (
    <div className="batch-details flex-grow-1 d-flex flex-column">
      {showExportBatch &&
        <ExportBatch handleHide={handleHideExportBatch}
                     data={gridData}
                     batchId={batch_id}
                     batchName={batchDetailData.list.name}
                     settingOptions={settingOptions}
        />
      }
      {addCards &&
      <AddCards batchId={batch_id} handleHide={handleHideAddCards} handleAction={() => {history.push('/batches/')}}/>
      }
      {removeBatch &&
      <AlertYesNoModal
        handleAction={handleRemoveBatch}
        handleHide={handleRemoveBatchHide}
        modalType="remove_batch"
      />
      }
      {removeSelectedCardModal &&
      <AlertYesNoModal
        handleAction={handleRemoveSelected}
        handleHide={handleHideRemoveSelectedModal}
        modalType="delete_cards"
      />
      }
      {currentView === 'spreadsheet' &&
        <RouteLeavingGuard
          when={isDirty}
          navigate={navigate}
          shouldBlockNavigation={location => {
            if (isDirty) {
              if (!location.pathname.startsWith(`/batches/${batch_id}`)) {
                return true
              }
            }
            return false
          }}
        />}
      <div className="content-header-main">
        <div className="column left vert">
          <h1 style={{textTransform: 'none'}}
              contentEditable="true" suppressContentEditableWarning={true} onInput={onChangeBatchName}>
            {batchDetailData.list.name}
          </h1>
          <div className="breadcrumbs red-on-dark normal-mode-only">
            <Link to="/batches">Batches</Link>
            <ArrowIcon fill={'grey'} />
            <span className="current-page">{batchDetailData.list.name}</span>
          </div>
        </div>
        <div className="column right">
          <div className="tabs-input">
            <div className={"tab" + (currentView === 'gallery' ? ' active' : '')}
                 onClick={() => handleViewClick('gallery')}>
              <img src={galleryView} alt=""/>
              <p>Gallery View</p>
            </div>
            <div className={"tab spreadsheat-view-tab" + (currentView === 'spreadsheet' ? ' active' : '')}
                 onClick={() => handleViewClick('spreadsheet')}>
              <img src={excelIcon} alt=""/>
              <p>Spreadsheet View</p>
            </div>
          
          </div>
          <div className="input dropdown" onClick={handleShowExportBatch}>
            Export Batch
          </div>
          <div className="btn primary create-new-batch-btn" onClick={handleShowAddCards}>
            <img src={addIconWhite} alt=""/>
            <p>Add cards</p>
          </div>
          <div className="menu-m">
            <div className="menu-opener" onClick={handleRemoveBatchMenu}>
              <img src={menuImg} alt=""/>
            </div>
            {removeBatchMenuVisible &&
            <div className="menu-m-options">
              <div className="option" onClick={handleRemoveBatchModal}>
                <img src={deleteRed} alt=""/>
                <p>Remove Batch</p>
              </div>
              <div className="option" onClick={handleChangeBatchStatus}>
                {batchDetailData.list.status === "Open" ? <img src={lockClosedIcon} alt=""/> : <img src={lockOpen16} alt=""/> }
                {batchDetailData.list.status === "Open" ? <p>Change status to Closed</p> : <p>Change status to Open</p> }
              </div>
              {bannerOption !== "None" && <div className="option" onClick={handleAddBannerToCards}>
                <img src={bannerImage} alt=""/>
                <p>Add Banner To Cards</p>
              </div>}
            </div>
            }
          </div>
        </div>
        <div className="btn primary round floating create-new-batch-btn">
          <img src={addIconWhite} alt=""/>
        </div>
      </div>
      <div className="sec-footer">
        {currentView === 'gallery' &&
        <div>
          <div className="select-all-action" onClick={handleSelectAll}>Select All</div>
          <div className="deselect-all-action" onClick={handleDeselectAll}>Deselect All</div>
        </div>
        }
        <div onClick={handleRemoveSelectedModal}>
          <img src={deleteRed} alt=""/>
          <p>Remove Selected</p>
        </div>
      </div>
      <div className={"content-container flex-grow-1"}>
        {currentView === 'gallery' ?
          <BatchGalleryView batchDetailData={batchDetailData}
                            batch_id={batch_id}
                            setBatchDetailData={setBatchDetailData} /> :
          <BatchSpreadSheetView gridData={gridData} setGridData={setGridData} setIsDirty={setIsDirty}
                                gridAPI={gridAPI}
                                onGridReady={onGridReady}
                                handleSaveData={handleSaveData}
                                batchInfo={{ batch_id, batchCustomFields, settingOptions }}
          />
        }
      </div>
      
    </div>
  )
}

export default BatchDetail;