import React, {useEffect, useState} from "react";
import {deepCloneObject} from "../../helpers/commonHelper";
import ApiHelper from "../../helpers/apiHelper";
import API_URL from "../../consts/urls";
import {toastr} from "react-redux-toastr";

const SKUSetting = ({updateSetting, setDirty}) => {
  const [skuSetting, setSKUSetting] = useState(null);

  const onChangeSKUSetting = (e) => {
    setSKUSetting({
      ...skuSetting,
      [e.target.name]: e.target.value
    })
    setDirty(true)
  }

  const onChangeSKUNumericSetting = (e) => {
    let value = parseInt(e.target.value);
    {
      setSKUSetting({
        ...skuSetting,
        [e.target.name]: isNaN(value) ? '': value
      })
    }
    setDirty(true)
  }
  const onChangeSKUSettingChecks = (e) => {
    setSKUSetting({
      ...skuSetting,
      [e.target.name]: !skuSetting[e.target.name]
    })
    setDirty(true)
  }

  useEffect(() => {
    ApiHelper.get(API_URL.SKUSetting).then(res => {
      if (res.data.sku != null) {
        setSKUSetting(res.data.sku)
      }
    }).catch(e => {
    }).finally(() => {
    });
  }, []);

  useEffect(() => {
    updateSetting(skuSetting);
  }, [skuSetting])

  return (
    skuSetting ? <div className="other-settings sku">
      <div className="d-flex flex-column">
        <div className={'custom-control custom-checkbox checkbox-row mb-3'}>
          <input type="checkbox" className="custom-control-input" checked={skuSetting.auto_generation}
                onChange={onChangeSKUSettingChecks} name="auto_generation" id={"auto_generation"} />
          <label className="custom-control-label" htmlFor="auto_generation">Have CDP Auto Generate SKU</label>
        </div>
        <div className={'custom-control custom-checkbox checkbox-row mb-3'}>
          <input type="checkbox" className="custom-control-input" checked={skuSetting.restrict_duplication}
                onChange={onChangeSKUSettingChecks} name="restrict_duplication" id={"restrict_duplication"} />
          <label className="custom-control-label" htmlFor="restrict_duplication">Restrict SKU Duplication</label>
        </div>
        <div className={'custom-control custom-checkbox checkbox-row'}>
          <input type="checkbox" className="custom-control-input" checked={skuSetting.use_cert}
                onChange={onChangeSKUSettingChecks} name="use_cert" id={"use_cert"} />
          <label className="custom-control-label" htmlFor="use_cert">Use Cert as SKU</label>
        </div>
      </div>
      <div className="mt-4 format-settings">
        <div className={"checkbox-row mb-3"}>
          <div className={"format-row flex-fill"} >
            <p>Format</p>
            <select name="sku_format" id="sku_format" onChange={onChangeSKUSetting} value={skuSetting.sku_format}>
              <option value="Numeric">Numeric</option>
              <option value="Alpha-Numeric">Alpha-Numeric</option>
            </select>
          </div>
          <div className={"format-row flex-fill"}>
            <p>Type</p>
            <select name="sku_type" id="sku_type" onChange={onChangeSKUSetting} value={skuSetting.sku_type}>
              <option value="Incremental">Incremental</option>
              <option value="Random">Random</option>
            </select>
          </div>
        </div>
        <div className="row gx-0">
          <div className="col-6 checkbox-row">
            <div className={"format-row col px-0"} >
              <p>Length</p>
              <input type="text" name="sku_length" value={skuSetting.sku_length} onChange={onChangeSKUNumericSetting}/>
            </div>
            <div className={"format-row col px-0"} >
              <p>Starting Number</p>
              <input type="text" name="sku_starting_number" value={skuSetting.sku_starting_number} onChange={onChangeSKUNumericSetting}/>
            </div>
          </div>
        </div>
      </div>
    </div>: <></>
  )
}

export default SKUSetting;